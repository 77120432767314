import { PurchaseSuccess } from "./PurchaseSuccess";
import { PurchaseError } from "./PurchaseError";

export const PurchaseResult = ({ purchaseResult = {}, ...rest }) => {
  // A simple component to determine which result to show
  const { success = {}, error = {} } = purchaseResult;

  const showSuccess = Object.keys(success).length > 0;
  const showError = Object.keys(error).length > 0;

  if (showSuccess) {
    return <PurchaseSuccess {...rest} />;
  }
  if (showError) {
    return <PurchaseError error={error} {...rest} />;
  }
  return null;
};

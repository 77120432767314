const STYLE_CONSTS = {
  CONTENT_RADIUS_MOD: 12,
  CONTENT_BORDER_MAX: 68,
  BUTTON_RADIUS_MOD: 24,
  BUTTON_BORDER_MAX: 48,
  BADGE_LEFT_MOD: -10,
  BADGE_TOP_MOD: 38,
  BADGE_WIDTH_MOD: 2.8,
  BADGE_FONT_SIZE_MOD: 44,
  BADGE_FONT_SIZE_MIN: 11,
  BADGE_LINE_HEIGHT_MOD: 32,
  BADGE_MAX_WIDTH: 6,
};

const validateWidth = (width) => {
  try {
    if (typeof width !== "number") {
      throw new Error("Width must be a number");
    }
    if (width < 0) {
      throw new Error("Width must be a positive number");
    }
  } catch (e) {
    throw new Error("Unknown error");
  }
}

const clampWidth = (width) => {
  try {
    validateWidth(width);
    if ((width / STYLE_CONSTS.BADGE_FONT_SIZE) < STYLE_CONSTS.BADGE_FONT_SIZE_MIN) {
      return STYLE_CONSTS.BADGE_FONT_SIZE_MIN * STYLE_CONSTS.BADGE_FONT_SIZE;
    }
    return width;
  } catch (e) {
    return 0;
  }
}

export const calcContainerRadius = (width) => {
  try {
    validateWidth(width);
    let newRadius = width / STYLE_CONSTS.CONTENT_RADIUS_MOD;
    if (newRadius > STYLE_CONSTS.CONTENT_BORDER_MAX) newRadius = STYLE_CONSTS.CONTENT_BORDER_MAX;
    return `${newRadius}px`;
  } catch (e) {
    return "0px";
  }
}

export const calcButtonRadius = (width) => {
  try {
    validateWidth(width);
    let newRadius = width / STYLE_CONSTS.BUTTON_RADIUS_MOD;
    if (newRadius > STYLE_CONSTS.BUTTON_BORDER_MAX) newRadius = STYLE_CONSTS.BUTTON_BORDER_MAX;
    return `${newRadius}px`;
  } catch (e) {
    return "0px";
  }
}

export const calcBadgeContainerStyles = (width) => {
  try {
    // We want to clamp the width for text based styles
    width = clampWidth(width);
    const styles = {
      left: width / STYLE_CONSTS.BADGE_LEFT_MOD + "px",
      top: width / STYLE_CONSTS.BADGE_TOP_MOD + "px",
      width: width / STYLE_CONSTS.BADGE_WIDTH_MOD + "px",
    };
    return styles;
  } catch (e) {
    return {};
  }
};

export const calcBadgeTextStyles = (width) => {
  try {
    // We want to clamp the width for text based styles
    width = clampWidth(width);
    const styles = {
      fontSize: width / STYLE_CONSTS.BADGE_FONT_SIZE_MOD + "px",
      lineHeight: width / STYLE_CONSTS.BADGE_LINE_HEIGHT_MOD + "px",
      maxWidth: width / STYLE_CONSTS.BADGE_MAX_WIDTH + "px",
    };
    return styles;
  } catch (e) {
    return {};
  }
}

import { FONT_SOURCES } from "src/consts/fonts";

export function loadFonts(fonts) {
  const existingLinks = document.querySelectorAll(
    'link[data-builder-added-font="true"]',
  );
  if (!fonts) return;
  const { headline, regular } = fonts;

  existingLinks.forEach((link) => link.remove());

  [headline, regular].forEach((font) => {
    if (!font) return;
    if (font.source === FONT_SOURCES.THIRD_PARTY) {
      const link = document.createElement("link");
      link.href = font?.url;
      link.rel = "stylesheet";
      link.setAttribute("data-builder-added-font", "true");
      document.head.appendChild(link);
    }
    if (font.source === FONT_SOURCES.CUSTOM) {
      const style = document.createElement("style");
      style.textContent = `@font-face {
          font-family: '${font.name}';
          src: url('${font.url}');
          font-display: swap;
        }`;
      document.head.appendChild(style);
    }
  });
}

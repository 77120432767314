import { configureStore, combineReducers } from "@reduxjs/toolkit";

import appReducer from "features/app/appSlice";
import shopReducer from "features/shop/shopSlice";
import purchaseReducer from "features/purchase/purchaseSlice";
import sessionReducer from "features/session/sessionSlice";
import deepLinkReducer from "features/deepLink/deepLinkSlice";

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: combineReducers({
      app: appReducer,
      shop: shopReducer,
      purchase: purchaseReducer,
      session: sessionReducer,
      deepLink: deepLinkReducer,
    }),
    preloadedState,
  });

export const store = setupStore();

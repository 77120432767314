import { Heading, ModalBody, ModalHeader, Text } from "@chakra-ui/react";
import { getContrastColor } from "utils/theme";

import PropTypes from "prop-types";

export const OfferError = ({ themeOverride = {} }) => {
  const textColor =
    themeOverride?.secondary?.color ??
    getContrastColor(themeOverride?.background?.color);

  return (
    <>
      <ModalHeader p="0" my="16px" textAlign="center">
        <Heading
          fontSize={"32px"}
          fontWeight={600}
          lineHeight={"32px"}
          textAlign={"center"}
          letterSpacing={"-1px"}
          color={textColor}
        >
          Sorry, we can’t find what you’re looking for.
        </Heading>
      </ModalHeader>
      <ModalBody p={0}>
        <Text
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"16px"}
          textAlign={"center"}
          letterSpacing={"0.14px"}
          color={textColor}
        >
          The item, sale, or promotion is no longer available. Please check out
          the latest offers in our shop.
        </Text>
      </ModalBody>
    </>
  );
};

OfferError.propTypes = {
  themeOverride: PropTypes.object,
};

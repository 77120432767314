import PropTypes from "prop-types";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { Box, Text, chakra, shouldForwardProp } from "@chakra-ui/react";

import { getContrastingStyles } from "utils/theme";

const AnimatedText = chakra(motion.p, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const Timer = ({
  timeLeft = {},
  color = "#000",
}) => {
  if (!timeLeft) return null;

  return (
    <Box
      display={"flex"}
      gap={"4px"}
      mx={"auto"}
      color={getContrastingStyles(color, "sectionPrimary").color}
    >
      {Object.entries(timeLeft).map(([unit, value]) =>
        value >= 0 ? (
          <Box display={"flex"} key={`counter-${unit}-${value}`}>
            <AnimatePresence>
              <AnimatedText
                fontSize={{
                  base: "10px",
                  md: "12px",
                }}
                fontWeight={700}
                lineHeight={{
                  md: "150%",
                  base: "normal",
                }}
                letterSpacing={{
                  md: "0.12px",
                  base: "0.08px",
                }}
                color={"inherit"}
                key={`counter-${unit}-${value}`}
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 10, opacity: 0 }}
              >
                {value}
              </AnimatedText>
            </AnimatePresence>
            <Text variant="itemAvailability">
              {` ${unit} `}
            </Text>
          </Box>
        ) : null
      )}
      <Text variant="itemAvailability">
        left
      </Text>
    </Box>
  );
};

Timer.propTypes = {
  timeLeft: PropTypes.object,
  color: PropTypes.string,
};

import { Box, HStack } from "@chakra-ui/react";

export const Skeleton = () => (
  <HStack
    data-testid="skeleton"
    gap={"8px"}
    width="100%"
    alignItems="center"
    justifyContent={"flex-end"}
    borderRadius={"24px"}
    border={"2px solid rgba(22, 23, 25, 0.15)"}
    boxShadow={"sm"}
    padding={"4px"}
    margin={"24px 0"}
  >
    <Box
      width="48px"
      height="40px"
      borderRadius={"48px"}
      border={"1px solid rgba(248, 250, 253, 0.15)"}
      opacity={0.5}
      bg={"rgba(22, 23, 25, 0.15)"}
    />
  </HStack>
);

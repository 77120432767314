import PropTypes from "prop-types";

import {
  Text,
  VStack,
} from "@chakra-ui/react";

import { getContrastingStyles } from "utils/theme";

export const ItemBadge = ({
  item = {},
  themeOverride = {},
}) => {
  const { badge = {} } = item;
  const label = badge?.label;

  if (!label) return <></>;

  return (
    <VStack
      position={"absolute"}
      left={{
        base: "-70px",
        smd: "-60px",
      }}
      top={{
        base: "12px",
        smd: "12px",
      }}
      width={{
        base: "200px",
        smd: "200px",
      }}
      minH={{
        base: "24px",
        smd: "35px",
      }}
      justifyContent={"center"}
      alignItems={"center"}
      transform={"rotate(-30deg)"}
      {...getContrastingStyles(themeOverride?.secondary?.color, "sectionSecondary")}
      zIndex={"1"}
    >
      <Text
        fontSize={{
          base: "9px",
          smd: "12px",
        }}
        lineHeight={{
          base: "11px",
          smd: "14px",
        }}
        letterSpacing={"0.24px"}
        fontWeight={{
          base: 600,
          md: 700,
        }}
        textAlign={"center"}
        maxWidth={{
          base: "60px",
          smd: "80px",
        }}
      >
        {label}
      </Text>
    </VStack>
  );
};

ItemBadge.propTypes = {
  item: PropTypes.object,
  themeOverride: PropTypes.object,
};

import React from "react";
import * as Sentry from "@sentry/react";
import { Box } from "@chakra-ui/react";

const isValidFallback = (fallbackUI) => {
  if (React.isValidElement(fallbackUI)) return true;
  // null is valid element and can be passed to
  // not show anything for a fallback
  if (fallbackUI === null) return true;
  return false;
};

const FallbackComponent = () => {
  return <Box>An error has occured</Box>;
};

export const ErrorBoundary = ({
  name = "", // Should be unique for each error boundary
  show = true,
  altUI = null,
  showAltUI = false,
  fallbackUI = null,
  children = null,
}) => {
  // if nothing is passed in for fallback UI, set it to null so
  // nothing shows if there is an error. Otherwise use the fallbackUI
  // passed if valid or fallback to the generic component
  const fallbackBool = Boolean(fallbackUI);
  if (!fallbackBool) fallbackUI = null;
  fallbackUI = isValidFallback(fallbackUI) ? fallbackUI : FallbackComponent;

  const renderChildren = () => {
    if (showAltUI && altUI) return altUI;
    if (!show || !children) return <></>;
    return children;
  };

  return (
    <Sentry.ErrorBoundary
      key={name}
      fallback={fallbackUI}
    >
      {renderChildren()}
    </Sentry.ErrorBoundary>
  );
};

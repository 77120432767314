import { Image, CardHeader, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";

import { getItemBodyImageFit } from "utils/customData";

export const ItemHeader = ({ item = {}, children }) => {
  const { name = "", imageUrl = "" } = item;

  return (
    <CardHeader
      w={"full"}
      p={"0"}
      position={"relative"}
      height={{
        smd: "120px",
        base: "80px",
      }}
    >
      <VStack
        width={"100%"}
        height={"100%"}
        overflow={"hidden"}
      >
        <Image
          src={imageUrl}
          alt={name}
          maxWidth={"none !important"}
          maxH={"none !important"}
          width={"100%"}
          height={"100%"}
          objectFit={getItemBodyImageFit()}
          objectPosition={"center"}
          borderTopLeftRadius={"md"}
          borderTopRightRadius={"md"}
          opacity={0}
          transition={"all 0.2s ease"}
          onLoad={(e) => {
            e.target.style.opacity = 1;
          }}
        />

        {children}
      </VStack>
    </CardHeader>
  );
};

ItemHeader.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    remainingQuantity: PropTypes.number,
  }),
};

import postalCodes from 'postal-codes-js';

export const EMAIL_ERROR_MESSAGE = "Please enter a valid email";
export const ZIP_CODE_ERROR_MESSAGE = "Please enter a valid ZIP Code";

export const isValidEmail = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(value) || EMAIL_ERROR_MESSAGE;
};

export const isValidZipCode = (zipCode, opts = {}) => {
  const { basic = false } = opts;
  if (!zipCode) return ZIP_CODE_ERROR_MESSAGE;
  // Not inDepth just means basic existence check
  if (basic) return true;
  // We only care about the zipCode being valid in general
  // not to a specific country right now
  const countries = ['US', 'GB', 'UK', 'CA'];
  for (const country of countries) {
    const validation = postalCodes.validate(country, zipCode);
    const isValid = typeof validation === 'boolean' && validation;
    if (isValid) return true;
  }
  return ZIP_CODE_ERROR_MESSAGE;
}

export const ADYEN_CLIENT_KEY = import.meta.env.VITE_APP_ADYEN_CLIENT_KEY;
export const ADYEN_SANDBOX_CLIENT_KEY = import.meta.env
  .VITE_APP_ADYEN_SANDBOX_CLIENT_KEY;
export const ADYEN_ENV = import.meta.env.VITE_APP_ADYEN_ENV;
export const ADYEN_SANDBOX_ENV = import.meta.env.VITE_APP_ADYEN_SANDBOX_ENV;

// All possible result codes from Adyen
// All successful payments will be "Authorised"
// everything else should be handled as an error
export const ADYEN_RESULT_CODES = {
  AUTHORISED: "Authorised",
  REFUSED: "Refused",
  ERROR: "Error",
  CANCELLED: "Cancelled",
};

const ERROR_TITLES = {
  [ADYEN_RESULT_CODES.REFUSED]: "Payment Refused",
  [ADYEN_RESULT_CODES.ERROR]: "Payment Error",
  [ADYEN_RESULT_CODES.CANCELLED]: "Payment Cancelled",
};

export const CREATE_SESSION_FAILED = "create-session-failed";
export const PURCHASE_DEFAULT_ERROR =
  "There was an unexpected error. Please try again later";
export const PURCHASE_ERROR_MESSAGES = {
  MAX_PURCHASES_REACHED:
    "You have reached the maximum number of purchases allowed for this item.",
  ITEM_NOT_PURCHASABLE:
    "This item is currently not available for purchase. Please try again with a different item.",
  PLAYER_NOT_ELIGIBLE:
    "You are not eligible to purchase this item. Please try again with a different item.",
};

const getErrorTitle = (resultCode) => {
  return ERROR_TITLES[resultCode] || "Payment Error";
};

const ERROR_MESSAGES = {
  [ADYEN_RESULT_CODES.REFUSED]: "Your payment was refused. Please try again.",
  [ADYEN_RESULT_CODES.ERROR]:
    "An error occurred during payment. Please try again.",
  [ADYEN_RESULT_CODES.CANCELLED]:
    "Your payment was cancelled. Please try again.",
};

const getErrorMessage = (resultCode) => {
  return ERROR_MESSAGES[resultCode] || "Please try again.";
};

export const validatePurchaseResult = (result) => {
  const requiredProperties = ["sessionResult", "sessionData", "resultCode"];
  const missingProperties = requiredProperties.filter(
    (prop) => !(prop in result)
  );
  return missingProperties.length === 0;
};

export const validatePurchaseError = (error) => {
  const requiredProperties = ["title", "message", "reason"];
  const missingProperties = requiredProperties.filter(
    (prop) => !(prop in error)
  );
  return missingProperties.length === 0;
};

export const isAuthorised = (result) => {
  const { resultCode = "" } = result;
  const match = resultCode === ADYEN_RESULT_CODES.AUTHORISED;
  return match && validatePurchaseResult(result);
};

export const onAuthorised = (result) => {
  /*
    result: {
      resultCode:
      sessionData:
      sessionResult:
    }
    No mods needed to this payload at the moment
    We can just return it as is
  */
  return result;
};

export const onRefused = (result) => {
  /*
    result: {
      resultCode:
      sessionData:
      sessionResult:
    }
    Transform into an error payload with a basic reason for now
  */
  const { resultCode = "" } = result;
  return {
    title: getErrorTitle(resultCode),
    message: getErrorMessage(resultCode),
    reason: resultCode,
  };
};

export const onError = () => {
  /*
    error: {
      name:
      message:
    }
    Unknown how trustworthy this error object is for customer facing messages.
    At the moment, we can just return a generic error message
  */
  return {
    title: getErrorTitle(ADYEN_RESULT_CODES.ERROR),
    message: getErrorMessage(ADYEN_RESULT_CODES.ERROR),
    reason: ADYEN_RESULT_CODES.ERROR,
  };
};

const ZERO_VALUE = "0.00";

export const checkSessionData = (session = {}, initialState = {}) => {
  let { providerData = {} } = session;
  const { sessionId = null, sessionData = null } = providerData || {};

  const data = { ...initialState?.session?.data || {} };
  if (sessionId && sessionData) {
    data.id = sessionId;
    data.sessionData = sessionData;
  } else {
    data.free = true;
  }

  return data;
}

export const formatPrice = (opts = {}) => {
  const {
    symbol = "",
    amount = ZERO_VALUE,
    zeroOverride = 0,
  } = opts;
  let amountToFormat = amount;
  // null, undefined, "", 0 can all trigger this so the override is set
  if (!amountToFormat) amountToFormat = ZERO_VALUE;
  return amountToFormat === ZERO_VALUE ? zeroOverride : `${symbol} ${amountToFormat}`;
}

const getPercentage = (amount, tax) => {
  try {
    if (!amount || !tax) return "";
    const percentage = (tax / amount) * 100;
    return `${Math.round(percentage)}%`;
  } catch (e) {
    return "";
  }
}

export const parseSessionData = (session = {}) => {
  const { price = {}, player = {} } = session;
  const {
    currency,
    symbol,
    baseAmount,
    taxAmount,
    vatAmount,
    estimatedTaxAmount,
    estimatedVatAmount,
    totalAmount,
    estimatedTotalAmount,
  } = price;

  const { zip, email } = player;

  // Used in determining if the price is free
  const formattedBaseAmount = formatPrice({
    symbol,
    amount: baseAmount,
    zeroOverride: "Free",
  });

  const priceData = {
    currency,
    symbol,
    baseAmount: formattedBaseAmount,
    taxAmount: formatPrice({
      symbol,
      amount: estimatedTaxAmount || taxAmount,
      zeroOverride: 0,
    }),
    vatAmount: formatPrice({
      symbol,
      amount: estimatedVatAmount || vatAmount,
      zeroOverride: 0,
    }),
    vatPercentage: getPercentage(baseAmount, estimatedVatAmount || vatAmount),
    totalAmount: formatPrice({
      symbol,
      amount: estimatedTotalAmount || totalAmount,
      zeroOverride: "Free",
    }),
    isEstimated: !!estimatedTotalAmount || !!estimatedTaxAmount || !!estimatedVatAmount,
    isFree: formattedBaseAmount === "Free" || formattedBaseAmount === ZERO_VALUE,
  };

  return {
    ...session,
    price: priceData,
    player: { zip: zip ?? "", email: email ?? "" },
  };
};

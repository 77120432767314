import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { settingsSelector } from "features/app/appSlice";
import CheckIcon from "assets/img/icons/check-circle.svg?react";

import { TRACKING_EVENTS, useTrackEvent } from "utils/tracking";

export const PurchaseSuccess = ({ item = {}, handleClose = () => {} }) => {
  const { purchaseSuccessMessage = "" } = useSelector(settingsSelector);

  const onContinue = () => {
    handleClose();
  };

  useTrackEvent(TRACKING_EVENTS.ITEM_PURCHASE_SUCCESS, { item });

  return (
    <PurchaseSuccessPresentation
      purchaseSuccessMessage={purchaseSuccessMessage}
      onContinue={onContinue}
    />
  );
};

PurchaseSuccess.propTypes = {
  item: PropTypes.object,
  handleClose: PropTypes.func,
};

export const PurchaseSuccessPresentation = ({
  purchaseSuccessMessage = "",
  onContinue = () => {},
}) => {
  return (
    <>
      <VStack
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        p={"40px"}
        gap={"40px"}
        position={"relative"}
      >
        <VStack
          justifyContent={"center"}
          alignSelf={"stretch"}
          alignItems={"center"}
          gap={"8px"}
        >
          <Heading
            fontSize={"32px"}
            fontWeight={600}
            lineHeight={"44.8px"}
            letterSpacing={"-1px"}
          >
            Purchase complete!
          </Heading>
          <Text
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"24px"}
            textAlign={"center"}
          >
            {purchaseSuccessMessage}
          </Text>
        </VStack>

        <Button
          size={"large"}
          colorScheme={"blue"}
          w={"full"}
          p={"16px 12px"}
          borderRadius={"8px"}
          border={"2px solid rgba(22, 23, 25, 0.15)"}
          boxShadow={"rgba(90, 90, 90, 0.11)"}
          onClick={onContinue}
          bg="modalPrimary"
          color={"largeHeadingColor"}
          _hover={{
            filter: "brightness(1.12)",
          }}
          _active={{
            filter: "brightness(0.85)",
          }}
        >
          Continue shopping
        </Button>
      </VStack>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <Icon
          as={CheckIcon}
          position={"absolute"}
          left={"50%"}
          transform={"translateX(-50%)"}
          top={"7px"}
          color={"modalPrimary"}
          h={"48px"}
          w={"48px"}
        />
      </motion.div>
    </>
  );
};

PurchaseSuccessPresentation.propTypes = {
  purchaseSuccessMessage: PropTypes.string,
  onContinue: PropTypes.func,
};

import { useEffect, useRef, useState } from "react";
import { Box, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import AdyenCheckout from "@adyen/adyen-web";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  ADYEN_CLIENT_KEY,
  ADYEN_ENV,
  isAuthorised,
  onAuthorised,
  onError,
  onRefused,
  ADYEN_SANDBOX_CLIENT_KEY,
  ADYEN_SANDBOX_ENV,
} from "utils/purchase";

import PropTypes from "prop-types";
import { TRACKING_EVENTS, useTrackEvent } from "utils/tracking";

// Base styles for Adyen components
import "@adyen/adyen-web/dist/adyen.css";

const CONTAINER_STYLES = {
  h: "75vh",
};

export const PurchaseCheckout = ({
  item = {},
  session = {},
  onPurchaseSuccess = () => {},
  onPurchaseError = () => {},
}) => {
  const dropinContainer = useRef(null);

  const [dropInReady, setDropInReady] = useState(false);
  const [dropInComponent, setDropInComponent] = useState(null);

  const getAdyenConfig = () => {
    const clientKey = session.data.sandbox
      ? ADYEN_SANDBOX_CLIENT_KEY
      : ADYEN_CLIENT_KEY;
    const environment = session.data.sandbox ? ADYEN_SANDBOX_ENV : ADYEN_ENV;

    let paymentMethodsConfiguration = {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
      },
    };

    return {
      environment: environment, // Change to 'live' for the live environment.
      clientKey: clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      session: session.data,
      onPaymentCompleted: (result) => {
        if (isAuthorised(result)) {
          onPurchaseSuccess(onAuthorised(result));
        } else {
          onPurchaseError(onRefused(result));
        }
      },
      onError: (error) => {
        onPurchaseError(onError(error));
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration,
    };
  };

  useEffect(() => {
    if (dropinContainer.current && session?.data?.sessionData && !dropInReady) {
      const generateDropIn = async (node) => {
        const checkout = await AdyenCheckout(getAdyenConfig());

        // Adyen MPI Fix
        try {
          checkout.paymentMethodsResponse.storedPaymentMethods.forEach((item, index, object) => {
            if (item.brand.endsWith('googlepay') || item.brand.endsWith('applepay')) {
              object.splice(index, 1);
            }
          });
        } catch (e) {
          console.error(e);
        }
        // End Adyen MPI Fix

        let dropIn = null;
        dropIn = checkout
          .create("dropin", {
            onReady: () => {
              if (!dropInReady) {
                setDropInReady(true);
                setDropInComponent(dropIn);
              }
            },
          })
          .mount(node);
      };
      generateDropIn(dropinContainer.current);
    }
    return () => {
      if (dropInComponent) {
        dropInComponent.unmount();
      }
    }
  }, [session, dropInReady]);

  useTrackEvent(TRACKING_EVENTS.ITEM_CHECKOUT, { item });

  return (
    <VStack {...(dropInReady ? CONTAINER_STYLES : {})} gap={"0"}>
      {!dropInReady && (
        <HStack m={"6rem 0 4rem 0"} justify={"center"}>
          <Spinner size={"xl"} />
        </HStack>
      )}

      <Box
        ref={dropinContainer}
        justify={"center"}
        m={"1rem"}
        data-testid="dropin-container"
        overflow={"auto"}
      />

      {dropInReady && (
        <HStack
          m={"0px 1rem 1rem 1rem"}
          gap={"8px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Icon
            as={LockIcon}
            h={"17px"}
            w={"17px"}
            mb={"3px"}
            color={"rgba(22, 23, 25, 0.50)"}
          />
          <Text fontSize={"15px"} color={"rgba(22, 23, 25, 0.50)"}>
            Payments processed securely by Sanlo
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

PurchaseCheckout.propTypes = {
  item: PropTypes.object,
  session: PropTypes.object,
  onPurchaseSuccess: PropTypes.func,
  onPurchaseError: PropTypes.func,
};

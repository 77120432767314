export const validateStorefront = (data = {}) => {
  try {
    const {
      storefront = {},
      ignore = false,
    } = data;
    if (ignore) return false;
    if (!storefront || !Object.keys(storefront).length) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const validateItem = (itemToCheck = {}, sections = []) => {
  // Happens after login to verify a selected item is still available
  // after fetching the new player specific data. The worst case scenario
  // is false positive and the player just needs to click the item again.
  try {
    for (const section of sections) {
      const { item = {}, items = [] } = section;
      if (item.id && item.id === itemToCheck.id) return true;
      for (const subItem of items) {
        if (subItem.id && subItem.id === itemToCheck.id) return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  setStatusDefaults,
  setRequestStatus,
  setResponseStatus,
  setErrorStatus,
} from "utils/request";
import {
  PURCHASE_DEFAULT_ERROR,
  PURCHASE_ERROR_MESSAGES,
  CREATE_SESSION_FAILED,
  checkSessionData,
  parseSessionData,
} from "utils/purchase";
import { copy } from "utils/general";
import { getDeviceInfo } from "features/session/utils.js";
import { TRACKING_EVENTS, trackEvent } from "utils/tracking";

import PurchaseService from "./purchaseApi";

export const purchaseThunks = {
  createSession: createAsyncThunk(
    "purchase/createSession",
    async (payload, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        const webshopId = state.app.webshopId;
        const { item = {}, email = "" } = payload;
        const requestPayload = {
          itemId: item.id,
          webshopId,
          deviceInformation: getDeviceInfo(),
        };
        if (email) {
          requestPayload.email = email;
        }
        const res = await PurchaseService.createSession(requestPayload);
        return res.data;
      } catch (e) {
        return rejectWithValue({ error: e.response.data });
      }
    },
  ),
  updateSession: createAsyncThunk(
    "purchase/updateSession",
    async (payload, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        const sessionId = state.purchase.session.sessionV2.id;
        const res = await PurchaseService.updateSession({
          sessionId,
          data: payload,
        });
        return res.data;
      } catch (e) {
        return rejectWithValue({ error: e.response.data });
      }
    },
  ),
  confirmSession: createAsyncThunk(
    "purchase/confirmSession",
    async (_, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        const sessionId = state.purchase.session.sessionV2.id;
        const res = await PurchaseService.confirmSession(sessionId);
        return res.data;
      } catch (e) {
        return rejectWithValue({ error: e.response.data });
      }
    },
  ),
  createAndConfirmSession: createAsyncThunk(
    "purchase/createAndConfirmSession",
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { item } = payload;
        trackEvent(TRACKING_EVENTS.ITEM_PURCHASE_RETRY, { item });
        await dispatch(purchaseThunks.createSession({ item })).unwrap();
        dispatch(purchaseThunks.confirmSession());
      } catch (e) {
        return rejectWithValue({ error: e.response.data });
      }
    },
  ),
};

const initialState = {
  purchaseResult: {
    success: {},
    error: {},
  },
  modal: {
    step: 0,
    direction: 0,
  },
  session: {
    isCreated: false,
    isUpdated: false,
    isConfirmed: false,
    free: false,
    data: {
      id: "",
      sessionData: "",
      sandbox: false,
    },
    sessionV2: {
      price: {
        currency: "USD",
        symbol: "$",
        baseAmount: "",
        taxAmount: 0,
        totalAmount: "",
        isEstimated: false,
      },
      zipRequired: false,
      id: "",
      player: {
        zip: "",
        email: "",
      },
    },
  },
  createSession: {
    requestStatus: setStatusDefaults(),
  },
  updateSession: {
    requestStatus: setStatusDefaults(),
  },
  confirmSession: {
    requestStatus: setStatusDefaults(),
  },
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState: initialState,
  reducers: {
    setModalStep: (state, action) => {
      const {
        step,
        direction = state.modal.direction || 1, // 0 = back, 1 = next
        prev = false,
        next = false,
        stepsLength = 0,
        reset = false,
      } = action.payload;

      let newStep = step;
      let newDirection = direction;

      if (typeof step === "number") {
        newStep = step;
        newDirection = direction;
      } else if (prev) {
        newStep = state.modal.step - 1;
        newDirection = 0;
      } else if (next) {
        newStep = state.modal.step + 1;
        newDirection = 1;
      }

      if (newStep < 0) {
        newStep = 0;
      } else if (newStep > stepsLength - 1) {
        newStep = stepsLength - 1;
      }

      state.modal.step = newStep;
      state.modal.direction = newDirection;

      if (reset) {
        state.modal.step = 0;
        state.modal.direction = 0;
        state.purchaseResult = copy(initialState.purchaseResult);
        state.session = copy(initialState.session);
      }
    },
    setModalClose: (state) => {
      state.modal.step = 0;
      state.modal.direction = 0;
      state.purchaseResult = copy(initialState.purchaseResult);
      state.session = copy(initialState.session);
    },
    setPurchaseSuccess: (state, action) => {
      state.purchaseResult.success = action.payload;
    },
    setPurchaseError: (state, action) => {
      state.purchaseResult.error = action.payload;
    },
    resetPurchaseResult: (state) => {
      state.purchaseResult = copy(initialState.purchaseResult);
    },
    resetSession: (state) => {
      state.session = copy(initialState.session);
    },
    resetFullPurchaseState: (state) => {
      state.purchaseResult = copy(initialState.purchaseResult);
      state.session = copy(initialState.session);
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Create purchase session
       *******************************************************************************/
      .addCase(purchaseThunks.createSession.pending, (state) => {
        state.session.isCreated = false;
        state.createSession.requestStatus = setRequestStatus(
          state.createSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.createSession.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const parsedData = parseSessionData(payload);
        state.session.sessionV2 = parsedData;
        state.session.free = parsedData?.price?.isFree;
        state.session.isCreated = true;
        state.createSession.requestStatus = setResponseStatus(
          state.createSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.createSession.rejected, (state, action) => {
        const { payload: { error: { errorCode = "" } = {} } = {} } = action;
        const message =
          PURCHASE_ERROR_MESSAGES[errorCode] || PURCHASE_DEFAULT_ERROR;
        state.purchaseResult.error = {
          title: "Purchase failed",
          message,
          error: CREATE_SESSION_FAILED,
        };
        state.session.isCreated = false;
        state.createSession.requestStatus = setErrorStatus(
          state.createSession.requestStatus,
        );
      })
      /*******************************************************************************
       * Update purchase session
       *******************************************************************************/
      .addCase(purchaseThunks.updateSession.pending, (state) => {
        state.session.isUpdated = false;
        state.updateSession.requestStatus = setRequestStatus(
          state.updateSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.updateSession.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.session.sessionV2 = parseSessionData(payload);
        state.session.isUpdated = true;
        state.updateSession.requestStatus = setResponseStatus(
          state.updateSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.updateSession.rejected, (state) => {
        state.session.isUpdated = false;
        state.updateSession.requestStatus = setErrorStatus(
          state.updateSession.requestStatus,
        );
      })
      /*******************************************************************************
       * Confirm purchase session
       *******************************************************************************/
      .addCase(purchaseThunks.confirmSession.pending, (state) => {
        state.session.isConfirmed = false;
        state.confirmSession.requestStatus = setRequestStatus(
          state.confirmSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.confirmSession.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { session = {} } = payload;
        state.session.data = checkSessionData(session, initialState);
        state.session.data.sandbox = session.sandbox;
        state.session.isConfirmed = true;
        state.confirmSession.requestStatus = setResponseStatus(
          state.confirmSession.requestStatus,
        );
      })
      .addCase(purchaseThunks.confirmSession.rejected, (state, action) => {
        const { payload: { error: { errorCode = "" } = {} } = {} } = action;
        const message =
          PURCHASE_ERROR_MESSAGES[errorCode] || PURCHASE_DEFAULT_ERROR;
        state.purchaseResult.error = {
          title: "Purchase failed",
          message,
          error: CREATE_SESSION_FAILED,
        };
        state.session.isConfirmed = false;
        state.confirmSession.requestStatus = setErrorStatus(
          state.confirmSession.requestStatus,
        );
      });
  },
});

export const {
  setModalStep,
  setModalClose,
  setPurchaseSuccess,
  setPurchaseError,
  resetPurchaseResult,
  resetSession,
  resetFullPurchaseState,
} = purchaseSlice.actions;

export const purchaseResultSelector = (state) => state.purchase.purchaseResult;
export const purchasePlayerSelector = (state) =>
  state.purchase.session.sessionV2.player;
export const purchaseModalSelector = (state) => state.purchase.modal;
export const purchaseSessionSelector = (state) => state.purchase.session;

export const createSessionSelector = (state) =>
  state.purchase.createSession.requestStatus;
export const updateSessionSelector = (state) =>
  state.purchase.updateSession.requestStatus;
export const confirmSessionSelector = (state) =>
  state.purchase.confirmSession.requestStatus;

export default purchaseSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    lastViewedItem: null,
    currentViewedItem: null,
    purchaseFromDeepLink: false,
  },
  reducers: {
    setCurrentViewedItem: (state, action) => {
      state.lastViewedItem = state.currentViewedItem;
      state.currentViewedItem = action.payload;
    },
    setPurchaseFromDeepLink: (state, action) => {
      state.purchaseFromDeepLink = action.payload;
    },
  },
});

export const { setCurrentViewedItem, setPurchaseFromDeepLink } =
  shopSlice.actions;
export const shopSelector = (state) => state.shop;

export default shopSlice.reducer;

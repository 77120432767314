import { Flex, Image, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { toKebabCase } from "utils/general";

import { getImageUrl, getBackgroundStyles } from "utils/theme";

export const Banner = ({ section = {}, selected = false, ...rest }) => {
  const { themeOverride = {}, files = [] } = section;
  const bannerImage = getImageUrl(files, "MAIN");

  return (
    <Flex
      position={"relative"}
      minHeight={bannerImage ? "auto" : "200px"}
      w={"100%"}
      justifyContent={"center"}
      data-scroll-target={`section-${section.id}`}
      id={`section-${toKebabCase(section.title)}`}
      overflow={"hidden"}
    >
      <VStack
        position="absolute"
        width="100%"
        height="100%"
        {...getBackgroundStyles(themeOverride, files, {}, true)}
      />
      <Image
        maxW={"var(--max-width)"}
        src={bannerImage}
        width={"100%"}
        height={"100%"}
        objectFit={"cover"}
        objectPosition={"center"}
        zIndex={0}
      />
      <VStack
        position="absolute"
        width="100%"
        height="100%"
        bg="transparent"
        zIndex={2}
        outline={`2px solid ${
          selected ? "var(--chakra-colors-brandRed)" : "transparent"
        }`}
        outlineOffset={"-8px"}
      />
    </Flex>
  );
};

Banner.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool,
};

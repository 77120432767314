import { Box, Flex, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";

import { Item } from "components/item";
import { SectionHeader } from "components/section-header";

import { getBackgroundStyles, themeToCSSVariables } from "utils/theme";
import { toKebabCase } from "utils/general";

export const Items = ({
  loggedIn = false,
  isLoading = false,
  section = {},
  globalTheme = {},
  pricingTiers = {},
  selected = false,
  onItemSelect = () => {},
}) => {
  const {
    id = "",
    items = [],
    themeOverride = {},
    files = [],
  } = section;

  return (
    <VStack
      w={"100%"}
      justifyContent={"center"}
      data-scroll-target={`section-${section.id}`}
      id={`section-${toKebabCase(section.title)}`}
      {...getBackgroundStyles(themeOverride, files)}
      outline={`2px solid ${
        selected ? "var(--chakra-colors-brandRed)" : "transparent"
      }`}
      outlineOffset={"-8px"}
      sx={{ ...themeToCSSVariables(themeOverride, globalTheme) }}
    >
      <SectionHeader section={section} />

      <VStack
        width={"100%"}
        maxW={"var(--max-width)"}
        gap={{
          md: "32px",
          base: "24px",
        }}
      >
        <Flex
          width={"100%"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          gap={{
            lg: "48px",
            md: "32px",
            base: "16px",
          }}
          p={{
            base: "0 16px 32px 16px",
          }}
        >
          {Boolean(items.length) &&
            items.map((item) => {
              if (!item) return null;
              const { id: itemId } = item;
              return (
                <Box
                  key={`key_item_${id}_${itemId}`}
                  mt={{
                    md: "24px",
                    base: "18px",
                  }}
                  mb={{
                    md: "24px",
                    base: "18px",
                  }}
                >
                  <Item
                    loggedIn={loggedIn}
                    isLoading={isLoading}
                    item={item}
                    pricingTiers={pricingTiers}
                    themeOverride={themeOverride}
                    globalTheme={globalTheme}
                    onSelect={onItemSelect}
                  />
                </Box>
              );
            })}
          {!items.length && (
            <Box key={`key_item_placeholder_${section.id}`}>
              <Item placeholder />
            </Box>
          )}
        </Flex>
      </VStack>
    </VStack>
  );
};

Items.propTypes = {
  loggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  globalTheme: PropTypes.object,
  pricingTiers: PropTypes.object,
  selected: PropTypes.bool,
  onItemSelect: PropTypes.func,
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import {
  appSelector,
  appThunks,
  storefrontSelector,
} from "features/app/appSlice";
import { loggedInSelector } from "features/session/sessionSlice";

import { Timer } from "components/timer";

import { lighten } from "color2k";
import { calculateTimeLeft } from "utils/timer";
import { getPlayerQuantity, getGlobalQuantity } from "utils/item";
import { getColor, getContrastingStyles } from "utils/theme";
import { getTertiaryColor } from "utils/customData";

export const FeatureItemData = ({ section = {} }) => {
  const dispatch = useDispatch();

  const { item = {}, themeOverride = {} } = section;
  const { endTime = "" } = item;

  const loggedIn = useSelector(loggedInSelector);
  const { webshopId } = useSelector(appSelector);
  const { theme } = useSelector(storefrontSelector);

  const primaryColor = getColor("primary", themeOverride, theme);
  const color = getTertiaryColor(primaryColor);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));
  const playerQuantity = getPlayerQuantity(item, loggedIn);
  const globalQuantity = getGlobalQuantity(item);

  useEffect(() => {
    if (!endTime) return;
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endTime));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (endTime && !timeLeft) {
      dispatch(appThunks.getWebshop(webshopId));
    }
  }, [timeLeft, endTime, webshopId, dispatch]);

  if (!timeLeft && !playerQuantity && !globalQuantity) return null;

  return (
    <Box
      role="timer"
      bg={color}
      color={getContrastingStyles(color, "regularTextColor").color}
      w="full"
      pt="8px"
      pb={"8px"}
      borderTopLeftRadius={"20px"}
      borderTopRightRadius={"20px"}
      top={{ base: "calc(-48px / 1.60)", md: "calc(-48px / 1.4)" }}
      border={`4px solid ${lighten(color, 0.2)}`}
      borderBottom={"none"}
      display={"flex"}
      width={"fit-content"}
      px={"16px"}
      gap={"16px"}
    >
      {/* <Timer timeLeft={timeLeft} color={color} /> */}
      {(playerQuantity || globalQuantity) && (
        <Text size={"regular"} variant="itemAvailability" ml={"8px"}>
          {playerQuantity || globalQuantity}
        </Text>
      )}
    </Box>
  );
};

FeatureItemData.propTypes = {
  section: PropTypes.object,
};

import { useEffect } from "react";

import { copy } from "utils/general";

export const TRACKING_EVENTS = {
  OFFER_CLICK: "OFFER_CLICK",
  ITEM_CLICK: "ITEM_CLICK",
  ITEM_UNAVAILABLE: "ITEM_UNAVAILABLE",
  ITEM_DISMISSED: "ITEM_DISMISSED",
  ITEM_REVIEW: "ITEM_REVIEW",
  ITEM_CHECKOUT: "ITEM_CHECKOUT",
  ITEM_PURCHASE_RETRY: "ITEM_PURCHASE_RETRY",
  ITEM_PURCHASE_SUCCESS: "ITEM_PURCHASE_SUCCESS",
  ITEM_PURCHASE_ERROR: "ITEM_PURCHASE_ERROR",
};

export const isValidEventData = (data = {}) => {
  try {
    // Check for any event trying to track an item that is
    // null or malformed
    if (data && data.hasOwnProperty("item")) {
      const { item } = data;
      if (!item?.id) return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

export const trackEvent = (event, data = {}) => {
  try {
    if (!data || typeof data !== "object") data = {};
    if (!isValidEventData(data)) return;
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(copy({ event, ...data }));
    }
  } catch (err) {
    console.error("Error tracking event", err);
  }
};

export const useTrackEvent = (eventName, eventData) => {
  useEffect(() => {
    trackEvent(eventName, eventData);
  }, []);
};

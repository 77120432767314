import PropTypes from "prop-types";

import {
  Text,
  VStack,
} from "@chakra-ui/react";

import { getContrastingStyles } from "utils/theme";
import {
  calcBadgeContainerStyles,
  calcBadgeTextStyles,
} from "./utils";

export const FeaturedBadge = ({
  contentDimensions = {},
  item = {},
  themeOverride = {},
}) => {
  const { width = 0 } = contentDimensions;
  const { badge = {} } = item;
  const label = badge?.label;

  if (!label || !width) return <></>;

  return (
    <VStack
      position={"absolute"}
      {...calcBadgeContainerStyles(width)}
      justifyContent={"center"}
      alignItems={"center"}
      transform={"rotate(-30deg)"}
      {...getContrastingStyles(themeOverride?.secondary?.color, "sectionSecondary")}
      zIndex={"1"}
    >
      <Text
        letterSpacing={"0.24px"}
        fontWeight="700"
        textAlign={"center"}
        {...calcBadgeTextStyles(width)}
      >
        {label}
      </Text>
    </VStack>
  );
};

FeaturedBadge.propTypes = {
  contentDimensions: PropTypes.object,
  item: PropTypes.object,
  themeOverride: PropTypes.object,
};

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  setStatusDefaults,
  setRequestStatus,
  setResponseStatus,
  setErrorStatus,
} from "utils/request";

import AppService from "./appApi";

import { generateTheme } from "utils/theme";
import { validateStorefront } from "utils/storefront";

import MessageHandler from "utils/message-handler";

export const appThunks = {
  getWebshop: createAsyncThunk("app/webshop", async (webshopId) => {
    if (MessageHandler.builderMode) return { ignore: true };
    const res = await AppService.getWebshop(webshopId);
    return res.data;
  }),
  getLatestUnpublishedWebshop: createAsyncThunk(
    "app/latestUnpublishedWebshop",
    async (payload) => {
      const res = await AppService.getLatestUnpublishedWebshop(payload);
      return res.data;
    },
  ),
};

export const appSlice = createSlice({
  name: "app",
  initialState: {
    // Just a shortcut to the webshop id, but it's not really needed
    webshopId: "",
    webshopTheme: {},
    editorMode: false,
    previewMode: false,
    selectedSection: {},
    getWebshop: {
      data: {
        storefront: {
          theme: {},
          pricingTiers: {},
          manualAuthConfig: {},
          settings: {},
        },
        layout: {
          header: {},
          footer: {},
          login: {},
          deepLink: {},
          pages: [{ name: "", sections: [] }],
        },
      },
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setId: (state, action) => {
      state.webshopId = action.payload;
    },
    setEditorMode: (state, action) => {
      state.editorMode = action.payload;
      state.previewMode = !action.payload;
      MessageHandler.editorMode = action.payload;
      MessageHandler.builderMode = true;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setStorefront: (state, action) => {
      const { payload = {} } = action;
      const { setResponse = false, data = {} } = payload;
      if (validateStorefront(data)) {
        state.webshopTheme = generateTheme(data);
        state.getWebshop.data = data;
      }
      if (setResponse) {
        if (data) {
          state.getWebshop.requestStatus = setResponseStatus(
            state.getWebshop.requestStatus,
          );
        } else {
          state.getWebshop.requestStatus = setErrorStatus(
            state.getWebshop.requestStatus,
          );
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * getWebshop
       *******************************************************************************/
      .addCase(appThunks.getWebshop.pending, (state) => {
        state.getWebshop.requestStatus = setRequestStatus(
          state.getWebshop.requestStatus,
        );
      })
      .addCase(appThunks.getWebshop.fulfilled, (state, action) => {
        const { payload = {} } = action;
        if (validateStorefront(payload)) {
          state.webshopTheme = generateTheme(payload);
          state.getWebshop.data = payload;
          state.getWebshop.requestStatus = setResponseStatus(
            state.getWebshop.requestStatus,
          );
        }
      })
      .addCase(appThunks.getWebshop.rejected, (state) => {
        state.getWebshop.requestStatus = setErrorStatus(
          state.getWebshop.requestStatus,
        );
      });
  },
});

export const { setId, setEditorMode, setStorefront, setSelectedSection } =
  appSlice.actions;

export const appSelector = (state) => state.app;

export const storefrontSelector = createSelector(
  appSelector,
  (app) => app.getWebshop.data.storefront,
);
export const globalThemeSelector = createSelector(
  appSelector,
  (app) => app.getWebshop.data.storefront.theme,
);
export const layoutSelector = createSelector(
  appSelector,
  (app) => app.getWebshop.data.layout,
);
export const headerSelector = createSelector(
  layoutSelector,
  (layout) => layout.header,
);
export const footerSelector = createSelector(
  layoutSelector,
  (layout) => layout.footer,
);
export const loginSelector = createSelector(
  layoutSelector,
  (layout) => layout.login,
);
export const deepLinkSelector = createSelector(
  layoutSelector,
  (layout) => layout.deepLink,
);
export const pagesSelector = createSelector(
  layoutSelector,
  (layout) => layout.pages,
);
export const sectionsSelector = createSelector(
  pagesSelector,
  (pages) => pages[0].sections,
);
export const pricingTiersSelector = createSelector(
  storefrontSelector,
  (storefront) => storefront.pricingTiers,
);
export const settingsSelector = createSelector(
  storefrontSelector,
  (storefront) => storefront.settings,
);

export default appSlice.reducer;

import HTTP from "utils/http";

const SANLO_WEB_API_URL = import.meta.env.VITE_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/webshops`;

class DeepLinkService {
  getOffer({ webshopId, deepLinkCode }) {
    return HTTP.req.get(`${BASE}/${webshopId}/deep-link/item/${deepLinkCode}`);
  }
}

export default new DeepLinkService();

import { Text, Flex, useTheme } from "@chakra-ui/react";

export const WebshopNotFound = () => {
  const theme = useTheme();
  console.log("No webshop ID found, webshopId query param can be used.");
  return (
    <Flex
      zIndex={"1001"}
      position={"relative"}
      h={"100%"}
      w={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={theme.colors.itemBackground}
    >
      <Text
        fontSize={"1.5rem"}
        fontWeight={"bold"}
        color={theme.colors.tertiary}
      >
        Oops, there was an error loading the webshop.
      </Text>
    </Flex>
  );
};

import { SectionsLinks } from 'components/sections-links';
import { Banner } from './banner';
import { Featured } from './featured';
import { Items } from './items';

import { SECTION_TYPES } from 'consts/sections';

export const SectionComponents = {
  [SECTION_TYPES.BANNER]: Banner,
  [SECTION_TYPES.FEATURED_ITEM]: Featured,
  [SECTION_TYPES.ITEMS]: Items,
  [SECTION_TYPES.NAVIGATION]: SectionsLinks,
};

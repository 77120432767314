import { VStack } from "@chakra-ui/react";

export const ScrollContainer = ({ children }) => (
  <VStack
    width={"100%"}
    height={"100%"}
    overflow={"auto"}
    gap={0}
    pt={"var(--header-height)"}
  >
    {children}
  </VStack>
);

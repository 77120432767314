import {
  Heading,
  Image,
  ModalBody,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getItemBodyImageFit } from "utils/customData";
import { getContrastColor } from "utils/theme";

import DefaultImage from "./image/SanloDeepLink_placeholder.png?react";

import PropTypes from "prop-types";

export const OfferSuccess = ({
  layout = {},
  item = {},
  editorMode = false,
}) => {
  const { name = "", imageUrl = "" } = item;
  const { headline = "", description = "", themeOverride = {} } = layout;

  const textColor =
    themeOverride?.secondary?.color ??
    getContrastColor(themeOverride?.background?.color);

  return (
    <>
      <ModalHeader py={"0"} pb={"10px"} textAlign={"center"}>
        <Heading
          fontSize={"36px"}
          fontWeight={600}
          lineHeight={"32px"}
          textAlign={"center"}
          letterSpacing={"-1px"}
          color={textColor}
        >
          {headline}
        </Heading>
      </ModalHeader>
      <ModalBody p={0}>
        <VStack gap={"10px"}>
          <Text
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"16px"}
            textAlign={"center"}
            letterSpacing={"0.14px"}
            color={textColor}
          >
            {description}
          </Text>

          <Image
            src={editorMode ? DefaultImage : imageUrl}
            alt={editorMode ? "Default image" : name}
            maxWidth={"360px !important"}
            maxH={"160px !important"}
            objectFit={getItemBodyImageFit()}
            objectPosition={"center"}
            borderRadius={"md"}
            opacity={0}
            transition={"all 0.2s ease"}
            onLoad={(e) => {
              e.target.style.opacity = 1;
            }}
          />

          <Text
            fontSize={"27px"}
            fontWeight={500}
            lineHeight={"normal"}
            textAlign={"center"}
            color={textColor}
          >
            {editorMode ? "Your item's name" : name}
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

OfferSuccess.propTypes = {
  editorMode: PropTypes.bool,
  item: PropTypes.object,
  layout: PropTypes.object,
};

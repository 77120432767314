import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { lighten } from "color2k";
import { useDispatch, useSelector } from "react-redux";

import {
  appSelector,
  appThunks,
  storefrontSelector,
} from "features/app/appSlice";
import { Timer } from "components/timer"

import { calculateTimeLeft, checkTimeLeft } from "utils/timer";
import { getColor } from "utils/theme";
import { getTertiaryColor } from "utils/customData";

export const CountdownTimer = ({ endTime, themeOverride }) => {
  const dispatch = useDispatch();

  const { webshopId } = useSelector(appSelector);
  const { theme } = useSelector(storefrontSelector);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

  const primaryColor = getColor("primary", themeOverride, theme);
  const color = getTertiaryColor(primaryColor);

  useEffect(() => {
    if (!endTime) return;
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endTime));
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (endTime && !timeLeft) {
      dispatch(appThunks.getWebshop(webshopId));
    }
  }, [timeLeft, endTime, webshopId, dispatch]);

  if (!checkTimeLeft(timeLeft)) return null;

  return (
    <Box
      role="timer"
      bg={color}
      w="full"
      pt="8px"
      px={"4px"}
      pb={"48px"}
      borderRadius={"20px"}
      pos={"absolute"}
      top={{ base: "calc(-48px / 1.60)", md: "calc(-48px / 1.4)" }}
      border={`4px solid ${lighten(color, 0.2)}`}
      display={"flex"}
    >
      <Timer timeLeft={timeLeft} color={color} />
    </Box>
  );
};

CountdownTimer.propTypes = {
  endTime: PropTypes.string,
  themeOverride: PropTypes.object,
};

export default CountdownTimer;

export const MESSAGE_TYPES = {
  APP_READY: "app-ready",
  CONFIG: "config",
  UPDATE: "update",
  SECTION_SELECTED: "section-selected",
  ADD_NEW_SECTION: "add-new-section",
  SET_TYPOGRAPHY: "set-typography",
};

class MessageHandler {
  constructor() {
    this.__editorMode = false;
    this.__builderMode = false;

    this.__handlers = {};
  }

  get editorMode() {
    return this.__editorMode;
  }

  set editorMode(mode) {
    this.__editorMode = mode;
  }

  get builderMode() {
    return this.__builderMode;
  }

  set builderMode(mode) {
    this.__builderMode = mode;
  }

  setupListener(namespace, eventHandlers) {
    if (!this.__handlers[namespace]) {
      this.__handlers[namespace] = (event) => {
        const { data: eventData = {} } = event;
        const { type = "", data = {} } = eventData;
        if (!type) return;
        if (eventHandlers[type]) {
          eventHandlers[type](data);
        }
      };
    }
    window.addEventListener("message", this.__handlers[namespace]);
  }

  removeListener(namespace) {
    window.removeEventListener("message", this.__handlers[namespace]);
    this.__handlers[namespace] = null;
  }

  postMessage({ type = "", data = {} }) {
    // builderMode block normal message sending, but it can still be forced
    if (!this.builderMode) return;
    window.parent.postMessage(
      {
        type,
        data,
      },
      import.meta.env.VITE_APP_SANLO_WEB_UI_URL,
    );
  }

  sendAppReady() {
    this.postMessage({
      type: MESSAGE_TYPES.APP_READY,
    });
  }

  selectSection(section) {
    // No selection for preview mode
    if (!this.editorMode) return;
    this.postMessage({
      type: MESSAGE_TYPES.SECTION_SELECTED,
      data: section,
    });
  }

  addNewSection(section) {
    this.postMessage({
      type: MESSAGE_TYPES.ADD_NEW_SECTION,
      data: section,
    });
  }
}

export default new MessageHandler();

import { useRef } from "react";
import PropTypes from "prop-types";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { ErrorBoundary } from "components/error-boundary";

import ArrowRight from "images/icons/arrow-right.svg?react";
import { useForm } from "react-hook-form";
import { useAuthentication } from "src/hooks/useAuthentication";
import { AUTH_METHODS } from "src/consts/authMethods";

export const ManualAuth = ({ editorMode = false, provider }) => {
  const userIdInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    register,
    formState: { isDirty },
  } = useForm({
    values: {
      externalId: "",
      authMethod:
        (provider?.authMethod || provider?.type) ?? AUTH_METHODS.MANUAL,
    },
  });
  const { handleLogin, isLoading, error } = useAuthentication();
  const { idLabel, instructionImageUrl, instructionText, visible } = provider;

  function onSubmit(data) {
    if (editorMode) return;
    handleLogin(data);
  }

  return (
    <ErrorBoundary name="manual-auth" show={visible}>
      <VStack as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={error} mb={error ? "0" : "24px"}>
          <HStack
            gap={"8px"}
            width="100%"
            alignItems="center"
            borderRadius={"24px"}
            border={"2px solid rgba(22, 23, 25, 0.15)"}
            boxShadow={"sm"}
            padding={"0 4px 0 16px"}
          >
            {idLabel && (
              <Text
                fontSize="sm"
                fontWeight="600"
                color={"gray.500"}
                whiteSpace={"nowrap"}
                onClick={() => {
                  userIdInputRef.current.focus();
                }}
              >
                {idLabel}:
              </Text>
            )}
            <Input
              ref={userIdInputRef}
              {...register("externalId", { required: true })}
              h={"48px"}
              aria-label={idLabel}
              flex={"1"}
              size={"normal"}
              color={error ? "red.500" : "normalTextColor"}
              borderRadius={"24px"}
              border="none !important"
              boxShadow={"none !important"}
              outline={"none"}
              padding={"0"}
              _active={{
                border: "none !important",
                outline: "none !important",
              }}
              _focus={{
                border: "none !important",
                outline: "none !important",
              }}
              _focusVisible={{
                border: "none !important",
                outline: "none !important",
              }}
            />
            <Button
              bg={"loginPrimary"}
              padding={"12px 18px"}
              borderRadius={"48px"}
              position={"relative"}
              cursor={editorMode ? "not-allowed" : "pointer"}
              color={"loginPrimaryContrast"}
              boxShadow={"md"}
              isDisabled={editorMode || !isDirty}
              isLoading={isLoading}
              width={"48px"}
              type="submit"
              aria-label={`Login with ${idLabel}`}
            >
              <Icon
                as={ArrowRight}
                fill={"white"}
                height={"24px"}
                width={"24px"}
              />
            </Button>
          </HStack>
          <FormErrorMessage p={"0"} mt={"0"} height={"24px"}>
            Failed to confirm ID, please try again.
          </FormErrorMessage>
        </FormControl>

        <ErrorBoundary
          name="manual-auth-instructions"
          show={Boolean(instructionText || instructionImageUrl)}
        >
          <Accordion
            allowToggle
            width="100%"
            border="none"
            defaultIndex={editorMode ? [0] : []}
          >
            <AccordionItem border="none">
              <h2>
                <AccordionButton p="0">
                  <Text variant="loginGeneric">
                    {`Can't find your ${idLabel || "ID"}?`}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p="0">
                {instructionText && (
                  <Text
                    fontSize="sm"
                    fontWeight="400"
                    whiteSpace={"pre-line"}
                    dangerouslySetInnerHTML={{
                      __html: instructionText,
                    }}
                  />
                )}
                {instructionImageUrl && (
                  <Box cursor="pointer" onClick={onOpen}>
                    <Image
                      mt="16px"
                      borderRadius="8px"
                      width="100%"
                      maxHeight="220px"
                      objectFit={"contain"}
                      src={instructionImageUrl}
                    />
                  </Box>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ErrorBoundary>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={"transparent"}
          onClick={onClose}
        >
          <ModalBody p={0} overflow="hidden">
            <Flex justifyContent="center" alignItems="center" height="100%">
              <ModalCloseButton onClick={onClose} />
              <Image
                src={instructionImageUrl}
                objectFit="contain"
                objectPosition="center"
                maxHeight="100vh"
                maxWidth="100vw"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

ManualAuth.propTypes = {
  skeleton: PropTypes.bool,
  provider: PropTypes.object,
  editorMode: PropTypes.bool,
};

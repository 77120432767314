import * as Sentry from "@sentry/browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  setStatusDefaults,
  setRequestStatus,
  setResponseStatus,
  setErrorStatus,
} from "utils/request";

import DeepLinkService from "./deepLinkApi";

export const deepLinkThunks = {
  getOffer: createAsyncThunk(
    "deepLink/getOffer",
    async (deepLinkCode, { getState, rejectWithValue }) => {
      const { webshopId } = getState().app;
      try {
        const res = await DeepLinkService.getOffer({ webshopId, deepLinkCode });
        return res.data;
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue({ error: true });
      }
    },
  ),
};

export const deepLinkSlice = createSlice({
  name: "deepLink",
  initialState: {
    offer: {
      showOffer: false,
      data: {},
      getOffer: {
        requestStatus: setStatusDefaults(),
      },
    },
  },
  reducers: {
    setShowOffer: (state, action) => {
      state.offer.showOffer = action.payload;
    },
    resetOfferRequestStatus: (state) => {
      state.offer.getOffer.requestStatus = setStatusDefaults();
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Get Offer
       *******************************************************************************/
      .addCase(deepLinkThunks.getOffer.pending, (state) => {
        state.offer.getOffer.requestStatus = setRequestStatus(
          state.offer.requestStatus,
        );
      })
      .addCase(deepLinkThunks.getOffer.fulfilled, (state, action) => {
        const { payload } = action;
        state.offer.data = payload;
        state.offer.showOffer = true;
        state.offer.getOffer.requestStatus = setResponseStatus(
          state.offer.getOffer.requestStatus,
        );
      })
      .addCase(deepLinkThunks.getOffer.rejected, (state) => {
        state.offer.data = {};
        state.offer.showOffer = true;
        state.offer.getOffer.requestStatus = setErrorStatus(
          state.offer.getOffer.requestStatus,
        );
      });
  },
});

export const { setShowOffer, resetOfferRequestStatus } = deepLinkSlice.actions;

export const offerSelector = (state) => state.deepLink.offer;

export default deepLinkSlice.reducer;

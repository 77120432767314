import * as Sentry from "@sentry/browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { appThunks } from "features/app/appSlice";

import { OAUTH_FLOW_DATA } from "src/consts/authMethods";
import { generateRandomString } from "src/utils/string";

import {
  setStatusDefaults,
  setRequestStatus,
  setResponseStatus,
  setErrorStatus,
} from "utils/request";

import SessionService from "./sessionApi";

import HTTP from "utils/http";

export const sessionThunks = {
  createSession: createAsyncThunk(
    "session/login",
    async (userData, thunkAPI) => {
      try {
        const { webshopId } = thunkAPI.getState().app;
        const res = await SessionService.createSession({
          ...userData,
          webshopId,
        });
        HTTP.setAuthorization(res.data);
        thunkAPI.dispatch(appThunks.getWebshop(webshopId));
        return res.data;
      } catch (e) {
        Sentry.captureException(e);
        return thunkAPI.rejectWithValue({ error: true });
      }
    },
    {
      condition: (userData, { getState }) => {
        const { session } = getState();
        if (session.createSession.requestStatus.pending) {
          return false;
        }
      },
    }
  ),
  endSession: createAsyncThunk("session/logout", async (_, thunkAPI) => {
    HTTP.removeAuthorization();
    const { webshopId } = thunkAPI.getState().app;
    thunkAPI.dispatch(appThunks.getWebshop(webshopId));
  }),
  createSocialSession: createAsyncThunk(
    "session/createSocialSession",
    async (authMethod, { getState, rejectWithValue }) => {
      try {
        const { webshopId } = getState().app;
        const res = await SessionService.createSocialSession({
          webshopId,
          authMethod,
        });
        return res.data;
      } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue({ error: true });
      }
    }
  ),
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    showLogin: false,
    loggedIn: false,
    user: {
      accessToken: "",
      player: {
        id: "",
      },
    },
    createSession: {
      requestStatus: setStatusDefaults(),
    },
    socialAuth: {
      data: {},
      requestStatus: setStatusDefaults(),
    },
  },
  reducers: {
    setUserData: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    toggleLogin: (state) => {
      state.showLogin = !state.showLogin;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setOAuthData: (state, action) => {
      state.socialAuth.data = action.payload;
      state.showLogin = true;
      localStorage.removeItem(OAUTH_FLOW_DATA);
    },
    cleanSocialAuth: (state) => {
      state.socialAuth.data = {};
    },
    resetRequestStatus: (state) => {
      state.createSession.requestStatus = setStatusDefaults();
      state.socialAuth.requestStatus = setStatusDefaults();
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Create Session
       *******************************************************************************/
      .addCase(sessionThunks.createSession.pending, (state) => {
        state.createSession.requestStatus = setRequestStatus(
          state.createSession.requestStatus
        );
      })
      .addCase(sessionThunks.createSession.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.user = action.payload;
        Sentry.setUser({ id: action.payload.player?.id });
        state.showLogin = false;
        state.createSession.requestStatus = setResponseStatus(
          state.createSession.requestStatus
        );
      })
      .addCase(sessionThunks.createSession.rejected, (state) => {
        state.createSession.requestStatus = setErrorStatus(
          state.createSession.requestStatus
        );
      })
      .addCase(sessionThunks.endSession.fulfilled, (state) => {
        state.loggedIn = false;
        state.user = {};
        Sentry.setUser({ id: null });
      })
      /*******************************************************************************
       * Social Auth
       *******************************************************************************/
      .addCase(sessionThunks.createSocialSession.pending, (state) => {
        state.socialAuth.requestStatus = setRequestStatus(
          state.socialAuth.requestStatus
        );
      })
      .addCase(sessionThunks.createSocialSession.fulfilled, (state, action) => {
        const { consentScreenRedirectUrl, authMethod } = action.payload;

        if (consentScreenRedirectUrl) {
          const url = new URL(consentScreenRedirectUrl);
          const nonce = generateRandomString(10);

          const oathFlowData = {
            authMethod,
            nonce,
          };

          localStorage.setItem(OAUTH_FLOW_DATA, JSON.stringify(oathFlowData));
          url.searchParams.append("nonce", nonce);

          window.location.href = url.toString();
        }

        state.socialAuth.requestStatus = setResponseStatus(
          state.socialAuth.requestStatus
        );
      })
      .addCase(sessionThunks.createSocialSession.rejected, (state) => {
        state.socialAuth.requestStatus = setErrorStatus(
          state.socialAuth.requestStatus
        );
      });
  },
});

export const {
  setUserData,
  toggleLogin,
  setShowLogin,
  setOAuthData,
  cleanSocialAuth,
  resetRequestStatus,
} = sessionSlice.actions;

export const sessionSelector = (state) => state.session;
export const loggedInSelector = (state) => state.session.loggedIn;
export const createSessionSelector = (state) => state.session.createSession;
export const socialAuthSelector = (state) => state.session.socialAuth;

export default sessionSlice.reducer;

import PropTypes from "prop-types";
import { CardBody, Text } from "@chakra-ui/react";
import {
  getItemBodyVariant,
  getItemBodyField,
  getItemBodyColor,
} from "utils/customData";

export const ItemBody = ({ item = {} }) => {
  return (
    <CardBody
      display="flex"
      p={{
        base: "4px 8px",
        smd: "6px 10px",
      }}
    >
      <Text
        variant={getItemBodyVariant()}
        {...getItemBodyColor()}
      >
        {getItemBodyField(item)}
      </Text>
    </CardBody>
  );
};

ItemBody.propTypes = {
  item: PropTypes.object,
};

import moment from "moment";

export const REQUEST_STATUS = {
  complete: false,
  pending: true,
  error: false,
};

export const RESPONSE_STATUS = {
  complete: true,
  pending: false,
  error: false,
};

export const ERROR_STATUS = {
  complete: true,
  pending: false,
  error: true,
};

const getLastUpdated = () => {
  return {
    lastUpdated: new moment().unix(),
  };
};

export const getRequestStatus = () => {
  return {
    ...REQUEST_STATUS,
  };
};

export const getResponseStatus = () => {
  return {
    ...RESPONSE_STATUS,
    initialized: true,
    lastUpdated: getLastUpdated(),
  };
};

export const getErrorStatus = () => {
  return {
    ...ERROR_STATUS,
    initialized: true,
    ...getLastUpdated(),
  };
};

export const setRequestStatus = (currentStatus) => {
  currentStatus = {
    ...currentStatus,
    ...getRequestStatus(),
  };
  return currentStatus;
};

export const setResponseStatus = (currentStatus) => {
  currentStatus = {
    ...currentStatus,
    ...getResponseStatus(),
    ...getLastUpdated(),
  };
  return currentStatus;
};

export const setErrorStatus = (currentStatus) => {
  currentStatus = {
    ...currentStatus,
    ...getErrorStatus(),
    ...getLastUpdated(),
  };
  return currentStatus;
};

export const setStatusDefaults = (opts = {}) => {
  /*
    {
      initialized: false,
      complete: false,
      pending: false,
      error: false,
      lastUpdated: null,
    }
  */
  let defaultStatuses = {};
  ["initialized", "complete", "pending", "error"].forEach((status) => {
    defaultStatuses[status] = opts[status] || false;
  });
  return defaultStatuses;
};

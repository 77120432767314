export const SECTION_TYPE = "FOOTER";

export const TERMS_LABEL = "Terms of Service";
export const PRIVACY_LABEL = "Privacy Policy";

export const extractLinks = (data = {}) => {
  try {
    let {
      externalLinks = [],
      socialLinks = []
    } = data;

    if (!Array.isArray(externalLinks)) externalLinks = [];
    if (!Array.isArray(socialLinks)) socialLinks = [];

    const termsOfService = externalLinks.find((link) => link.label === TERMS_LABEL) || {};
    const privacyPolicy = externalLinks.find((link) => link.label === PRIVACY_LABEL) || {};

    // Filter out the terms and privacy links, we dont want to sort them
    const linksList = externalLinks.filter((link) => link.label !== TERMS_LABEL && link.label !== PRIVACY_LABEL);
    // Sort the remaining custom links, this needs to be done because the order from services is not guaranteed
    linksList.sort((a, b) => a.position - b.position);
    // Create the merged list (terms and policy first) removing any links that are not visible
    const allLinks = [termsOfService, privacyPolicy, ...linksList].filter((link) => {
      if (!link || typeof link !== "object") return false;
      return link?.visible;
    });

    const filteredSocials = socialLinks.filter((social) => {
      if (!social || typeof social !== "object") return false;
      const {
        visible = true,
        linkUrl = "",
        platform = {},
      } = social;
      return visible && linkUrl && platform;
    });

    return {
      links: allLinks,
      socials: filteredSocials,
    };
  } catch (error) {
    console.log("Error extracting links", error);
    return {
      links: [],
      socials: [],
    };
  }
};

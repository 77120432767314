import { sessionThunks } from "features/session/sessionSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

export function useAuthentication() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  async function handleLogin(data) {
    if (data) {
      setError(null);
      setIsLoading(true);
      try {
        await dispatch(sessionThunks.createSession(data)).unwrap();
      } catch (e) {
        setError(e.error);
      }
      setIsLoading(false);
    }
  }
  return { handleLogin, isLoading, error };
}

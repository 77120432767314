import { parseThousands } from "utils/general";

export const getPlayerQuantity = (item = {}, loggedIn = false) => {
  // We only care about these if they are NOT null
  let {
    quantityPerPlayer = null, // Can't be null if player quantity is being used
    playerPurchaseCount = 0,
  } = item;
  if (!quantityPerPlayer) return null;
  if (!playerPurchaseCount) playerPurchaseCount = 0;
  if (loggedIn) {
    // "X/X left"
    return `${quantityPerPlayer - playerPurchaseCount}/${quantityPerPlayer} Left`;
  } else {
    // "X available"
    return `${quantityPerPlayer} Available`;
  }
}

export const getGlobalQuantity = (item = {}) => {
  const { remainingQuantity = 0 } = item;
  if (!remainingQuantity) return null;
  return `${parseThousands(remainingQuantity)} AVAILABLE`;
}

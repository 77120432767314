export const TextVariants = {
  variants: {
    itemAvailability: {
      lineHeight: {
        md: "150%",
        base: "normal",
      },
      letterSpacing: {
        md: "0.12px",
        base: "0.08px",
      },
      color: "inherit",
    },
    loginGeneric: {
      textAlign: "center",
      fontWeight: 500,
      fontSize: "16px",
    },
    price: {
      whiteSpace: "nowrap",
    },
    itemBodyMultiLine: {
      // Two lines of text
      height: {
        base: "24px",
        smd: "36px",
      },
      fontWeight: {
        lg: 700,
        base: 600,
      },
      fontSize: {
        base: "12px",
        smd: "16px",
      },
      lineHeight: {
        base: "12px",
        smd: "18px",
      },
      overflow: "hidden",
      width: "100%",
    },
    itemBodySingleLarge: {
      height: {
        base: "18px",
        smd: "24px",
      },
      fontWeight: {
        base: 700,
      },
      fontSize: {
        base: "18px",
        smd: "24px",
      },
      lineHeight: {
        base: "18px",
        smd: "24px",
      },
      overflow: "hidden",
      width: "100%",
      color: "var(--section-primary)",
    },
  },
};

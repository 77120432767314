import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { extendTheme, ChakraProvider, VStack } from "@chakra-ui/react";

import { Shop } from "../shop";

import MessageHandler, { MESSAGE_TYPES } from "utils/message-handler";
import HTTP from "utils/http";

import {
  appSelector,
  setId,
  setEditorMode,
  setSelectedSection,
  setStorefront,
  deepLinkSelector,
} from "./appSlice";
import { setUserData, setOAuthData } from "features/session/sessionSlice";

import { deepLinkThunks } from "features/deepLink/deepLinkSlice";
import { OAUTH_FLOW_DATA } from "src/consts/authMethods";

import { generateTheme } from "utils/theme";
import { scrollToTarget } from "utils/general";
import isEmpty from "lodash.isempty";
import { loadFonts } from "utils/fonts";

export const App = ({ preloadedData = null }) => {
  const dispatch = useDispatch();

  const [extendedTheme, setExtendedTheme] = useState({
    extended: false,
    theme: extendTheme(generateTheme()),
  });

  const appData = useSelector(appSelector);
  const { webshopTheme = {} } = appData;

  const deepLink = useSelector(deepLinkSelector);

  useEffect(() => {
    const urlObj = new URL(window.location.href);

    const hashString = urlObj.hash.substring(1);
    const hashParams = new URLSearchParams(hashString);
    const state = hashParams.get("state");
    const token = hashParams.get("id_token");
    const oauthFlowData = JSON.parse(localStorage.getItem(OAUTH_FLOW_DATA));
    const callCallback = !!token && !!state && !!oauthFlowData;

    if (callCallback) {
      dispatch(setOAuthData({ token, state, ...oauthFlowData }));
    }
  }, [dispatch]);

  useEffect(() => {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);
    const deepLinkCode = params.get("deepLinkCode");

    // Check deepLink ( if its present in layout), and deepLinkCode is present in the URL
    if (!isEmpty(deepLink) && deepLinkCode) {
      dispatch(deepLinkThunks.getOffer(deepLinkCode));
    }
  }, [dispatch, deepLink]);

  useEffect(() => {
    const {
      webshopId: preloadedWebshopId,
      webshop: preloadedWebshop,
      editorMode: preloadedEditorMode,
      validToken,
    } = preloadedData || {};

    // Check for an auth token in the cookies
    if (validToken) {
      const userData = HTTP.checkUserCookie(preloadedWebshopId);
      if (userData) {
        dispatch(setUserData(userData));
      }
    } else {
      HTTP.removeAuthorization();
    }

    dispatch(setId(preloadedWebshopId));
    dispatch(
      setStorefront({
        data: preloadedWebshop,
        setResponse: true,
      }),
    );

    const isIframe = window !== window.top;
    if (isIframe) {
      dispatch(setEditorMode(preloadedEditorMode === "true"));
      // Message handling for interaction with the builder
      MessageHandler.setupListener("app", {
        [MESSAGE_TYPES.CONFIG]: (data) => {
          const { storefront = null, editorMode = false } = data;
          dispatch(setEditorMode(editorMode));
          dispatch(setStorefront({ data: storefront }));
          loadFonts(storefront.storefront.themeV2.typography);
        },
        [MESSAGE_TYPES.UPDATE]: (data) => {
          dispatch(setStorefront({ data }));
        },
        [MESSAGE_TYPES.SECTION_SELECTED]: (data) => {
          scrollToTarget(`section-${data.id}`);
          dispatch(setSelectedSection(data));
        },
        [MESSAGE_TYPES.SET_TYPOGRAPHY]: (data) => {
          loadFonts(data);
        },
      });
      MessageHandler.sendAppReady();
      return () => {
        MessageHandler.removeListener("app");
      };
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Make sure the incoming theme isn't empty
    if (Object.keys(webshopTheme).length === 0) return;
    setExtendedTheme({
      extended: true,
      theme: extendTheme(webshopTheme),
    });
  }, [webshopTheme]);

  return (
    <ChakraProvider theme={extendedTheme.theme}>
      <VStack width="100vw" height="100vh">
        <Shop />
      </VStack>
    </ChakraProvider>
  );
};

App.propTypes = {
  preloadedData: PropTypes.object,
};

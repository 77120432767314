import { Box } from "@chakra-ui/react";

import { getBackgroundStyles } from "utils/theme";

export const Background = ({ storefront = {} }) => {
  const {
    theme = {},
    files = [],
  } = storefront;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: -1,
        ...getBackgroundStyles(theme, files),
      }}
    />
  );
};

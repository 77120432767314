import PropTypes from "prop-types";
import {
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  useTheme,
} from "@chakra-ui/react";
import { readableColor } from "color2k";

const PLATFORM_ICON_STYLES = {
  META: { mb: "4px", mr: "1px" },
};

export const Footer = ({
  id = "",
  logo = "",
  links = [],
  socials = [],
  isSelected = false,
  onFooterClick = () => {},
}) => {
  return (
    <HStack
      as={"footer"}
      width="100%"
      mt={"auto"}
      p={"16px 32px"}
      alignItems={"center"}
      alignSelf={"stretch"}
      justifyContent={"center"}
      boxShadow={"0px -2px 2px rgba(0, 0, 0, 0.1)"}
      bg={"footerBackground"}
      data-scroll-target={`section-${id}`}
      outline={`2px solid ${
        isSelected ? "var(--chakra-colors-brandRed)" : "transparent"
      }`}
      outlineOffset={"-8px"}
      onClick={onFooterClick}
    >
      <Stack
        width={"100%"}
        maxW={"var(--max-width)"}
        align={"center"}
        justify={"space-between"}
        direction={{
          base: "column-reverse",
          md: "row",
        }}
      >
        <Flex
          justifyContent={"space-around"}
          gap={"8px"}
          w={"100%"}
          align={"center"}
        >
          {logo && (
            <Image
              display={"block"}
              src={logo}
              height={"64px"}
              borderRadius={"8px"}
              mr="16px"
            />
          )}
          <FooterLinks links={links} key={"footer-links"} />
        </Flex>
        <FooterSocials socials={socials} key={"footer-socials"} />
      </Stack>
    </HStack>
  );
};

Footer.propTypes = {
  id: PropTypes.string,
  logo: PropTypes.string,
  links: PropTypes.array,
  socials: PropTypes.array,
  isSelected: PropTypes.bool,
  onFooterClick: PropTypes.func,
};

const FooterLinks = ({ links = [] }) => {
  const theme = useTheme();

  return (
    <Box
      flex={1}
      display={{
        base: "flex",
        smd: "block",
      }}
      flexDirection={"column"}
    >
      {links.map(({ id, url = "", label = "" }) => {
        return (
          <Link
            size={"normal"}
            display={"inline-block"}
            key={`key_link_${id}`}
            mr={"22px"}
            href={url}
            isExternal
            letterSpacing={"0.24px"}
            whiteSpace={"nowrap"}
            color={
              theme?.colors?.footerBackground
                ? readableColor(theme.colors.footerBackground)
                : "initial"
            }
          >
            {label}
          </Link>
        );
      })}
    </Box>
  );
};

FooterLinks.propTypes = {
  links: PropTypes.array,
};

const FooterSocials = ({ socials = [] }) => {
  return (
    <HStack justifyContent={"space-around"} gap={"12px"}>
      {socials.map(({ linkUrl = "", platform = {} }) => {
        const { id = "", iconUrl = "", name } = platform;
        const linkStyles = PLATFORM_ICON_STYLES[name.toUpperCase()] || {};
        return (
          <Flex
            key={`key_social_${id}`}
            w={"32px"}
            h={"32px"}
            alignItems={"center"}
            alignSelf={"stretch"}
            justifyContent={"center"}
            borderRadius={"100%"}
            borderColor={"transparent"}
            bgColor={"#383D38"}
            cursor={"pointer"}
          >
            <Link href={linkUrl} isExternal {...linkStyles}>
              <Image
                boxSize={4}
                objectFit={"cover"}
                src={iconUrl}
                alt={name}
                borderColor={"transparent"}
              />
            </Link>
          </Flex>
        );
      })}
    </HStack>
  );
};

FooterSocials.propTypes = {
  socials: PropTypes.array,
};

import { useState } from "react";
import PropTypes from "prop-types";
import { WrapItem, Card } from "@chakra-ui/react";

import { ItemBadge } from "./ItemBadge";

import { ItemQuantity } from "./ItemQuantity";
import { ItemHeader } from "./ItemHeader";
import { ItemBody } from "./ItemBody";
import { ItemFooter } from "./ItemFooter";
import CountdownTimer from "./ItemTimeLeftCountdown";
import { ItemLoader } from "components/loaders/ItemLoader";

import { getHoverStyle, getContrastingStyles } from "utils/theme";

export const Item = ({
  loggedIn = false,
  isLoading = false,
  item = {},
  pricingTiers = {},
  themeOverride = {},
  globalTheme = {},
  onSelect = () => {},
  placeholder = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <WrapItem
      position={"relative"}
      bg={"transparent"}
      d={"flex"}
      flexDirection={"column"}
      textAlign={"center"}
      m={"0 auto"}
      width={{
        smd: "170px",
        base: "120px",
      }}
      opacity={placeholder ? 0 : 1}
      cursor={(onSelect && !isLoading) ? "pointer" : "not-allowed"}
      onClick={() => {
        if (isLoading) return;
        if (typeof onSelect !== "function") return;
        onSelect(item);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {item.endTime ? (
        <CountdownTimer endTime={item.endTime} themeOverride={themeOverride} />
      ) : null}

      <ItemQuantity
        loggedIn={loggedIn}
        item={item}
        themeOverride={themeOverride}
        globalTheme={globalTheme}
      />

      <Card
        borderRadius={"40px"}
        overflow={"hidden"}
        backdropFilter={"blur(5px)"}
        position={"relative"}
        width={"100%"}
        {...getContrastingStyles()}
        {...getHoverStyle({
          isHovered,
          themeOverride,
          globalTheme,
        })}
      >
        <ItemLoader isLoading={isLoading} />

        <ItemBadge item={item} themeOverride={themeOverride} />

        <ItemHeader item={item} />

        <ItemBody item={item} themeOverride={themeOverride} />

        <ItemFooter
          loggedIn={loggedIn}
          item={item}
          pricingTiers={pricingTiers}
          themeOverride={themeOverride}
        />
      </Card>
    </WrapItem>
  );
};

Item.propTypes = {
  loggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  pricingTiers: PropTypes.object,
  themeOverride: PropTypes.object,
  globalTheme: PropTypes.object,
  onSelect: PropTypes.func,
  placeholder: PropTypes.bool,
};

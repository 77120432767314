import { Button, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import CrossIcon from "assets/img/icons/cross-circle.svg?react";
import { CREATE_SESSION_FAILED } from "utils/purchase";

import PropTypes from "prop-types";

import { TRACKING_EVENTS, useTrackEvent } from "utils/tracking";

export const PurchaseError = ({
  item = {},
  error = {},
  handleClose = () => {},
  handlePrevStep = () => {},
}) => {
  const isFailedSession = error?.error === CREATE_SESSION_FAILED;
  const buttonText = isFailedSession ? "Close" : "Try again";

  const handleClick = () => {
    if (isFailedSession) {
      handleClose();
      return;
    }
    handlePrevStep({
      reset: true,
    });
  };

  useTrackEvent(TRACKING_EVENTS.ITEM_PURCHASE_ERROR, { item, error });

  return (
    <>
      <VStack
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        p={"40px"}
        gap={"40px"}
        position={"relative"}
      >
        <VStack
          justifyContent={"center"}
          alignSelf={"stretch"}
          alignItems={"center"}
          gap={"8px"}
        >
          <Heading
            fontSize={"32px"}
            fontWeight={600}
            lineHeight={"44.8px"}
            letterSpacing={"-1px"}
          >
            {error.title}
          </Heading>
          <Text
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"24px"}
            textAlign={"center"}
          >
            {error.message}
          </Text>
        </VStack>

        <Button
          size={"large"}
          colorScheme={"blue"}
          w={"full"}
          p={"16px 12px"}
          borderRadius={"8px"}
          border={"2px solid rgba(22, 23, 25, 0.15)"}
          boxShadow={"rgba(90, 90, 90, 0.11)"}
          onClick={handleClick}
          bg="modalPrimary"
          color={"largeHeadingColor"}
          _hover={{
            filter: "brightness(1.12)",
          }}
          _active={{
            filter: "brightness(0.85)",
          }}
        >
          {buttonText}
        </Button>
      </VStack>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <Icon
          as={CrossIcon}
          position={"absolute"}
          left={"50%"}
          transform={"translateX(-50%)"}
          top={"7px"}
          color={"modalPrimary"}
          h={"48px"}
          w={"48px"}
        />
      </motion.div>
    </>
  );
};

PurchaseError.propTypes = {
  item: PropTypes.object,
  error: PropTypes.object,
  handleClose: PropTypes.func,
  handlePrevStep: PropTypes.func,
};

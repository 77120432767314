import HTTP from "utils/http";

const SANLO_WEB_API_URL = import.meta.env.VITE_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/webshops`;

class AppService {
  getWebshop(webshopId) {
    let URL = `${BASE}`;
    if (webshopId) URL += `/${webshopId}/storefront`;
    return HTTP.req.get(URL);
  }
}

export default new AppService();

import { useEffect } from "react";
import {
  IconButton,
  Image,
  Text,
  HStack,
  ModalFooter,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import {
  sessionThunks,
  socialAuthSelector,
  createSessionSelector,
  cleanSocialAuth,
} from "features/session/sessionSlice";
import { ErrorBoundary } from "components/error-boundary";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";

import PropTypes from "prop-types";

export const SocialAuth = ({
  editorMode = false,
  onlySocial = false,
  socialProviders = [],
}) => {
  const dispatch = useDispatch();
  const { requestStatus = {}, data = {} } = useSelector(socialAuthSelector);

  const { requestStatus: createSessionReq = {} } = useSelector(
    createSessionSelector
  );

  const showLoading =
    requestStatus.pending ||
    (requestStatus.complete && !requestStatus.error) ||
    createSessionReq.pending;

  const showError = requestStatus.error || createSessionReq.error;

  useEffect(() => {
    if (!isEmpty(data)) {
      const { state, token, authMethod } = data;
      dispatch(sessionThunks.createSession({ state, token, authMethod }));
      dispatch(cleanSocialAuth());
    }
  }, [data, dispatch]);

  const footerStyle = !onlySocial
    ? {
        borderTop: "1px solid",
        borderColor: "#C5C5C5",
        mt: "40px",
      }
    : {};

  return (
    <ErrorBoundary
      name="password-auth"
      show={Boolean(socialProviders.length > 0)}
    >
      <ModalFooter p={0} {...footerStyle}>
        <VStack alignItems={"center"} gap={"16px"} width={"100%"}>
          {!onlySocial && (
            <Text variant="loginGeneric" mt={"32px"}>
              Other ways to sign in
            </Text>
          )}
          <HStack gap={"16px"}>
            {showLoading ? (
              <Spinner
                data-testid={"provider-spinner"}
                thickness={"4px"}
                speed={"0.65s"}
                emptyColor={"gray.200"}
                color={"gray.500"}
                size={"xl"}
              />
            ) : (
              socialProviders.map((provider) => (
                <IconButton
                  key={provider.id}
                  aria-label={`provider-${provider.authMethod.toLowerCase()}`}
                  icon={
                    <Image
                      src={provider.iconUrl}
                      boxSize={"48px"}
                      objectFit={"cover"}
                      borderRadius={"8px"}
                    />
                  }
                  cursor={editorMode ? "not-allowed" : "pointer"}
                  onClick={() => {
                    if (editorMode) return;
                    dispatch(
                      sessionThunks.createSocialSession(provider.authMethod)
                    );
                  }}
                />
              ))
            )}
          </HStack>
          {showError && (
            <Text fontSize={"sm"} color={"red"}>
              Something went wrong, please try again.
            </Text>
          )}
        </VStack>
      </ModalFooter>
    </ErrorBoundary>
  );
};

SocialAuth.propTypes = {
  onlySocial: PropTypes.bool,
  editorMode: PropTypes.bool,
  socialProviders: PropTypes.array,
};

import { Box, Button, VStack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

export const AddSectionButton = ({
  pos = "bottom",
  onClick = () => {},
}) => {
  /*
    The only time "top" is used is for the first section, so you can adding something before it.
    We don't want that button to be hidden by or cover the header at all, so move it down
    into the section instead.
  */

  return (
    <VStack
      position={pos === "rel" ? "relative" : "absolute"}
      zIndex={3}
      top={pos === "top" ? 0 : "auto"}
      bottom={pos === "bottom" ? 0 : "auto"}
      left={0}
      right={0}
      transform={`translateY(${pos === "top" ? "0%" : "50%"})`}
      width={"100%"}
      alignItems={"center"}
      opacity={0.4}
      _hover={{
        opacity: 1,
      }}
    >
      <Button
        leftIcon={<AddIcon h={"12px"} w={"12px"} />}
        iconSpacing="12px"
        bg="#66C8FF"
        color="white"
        _hover={{
          background: "#66C8FF"
        }}
        onClick={onClick}
      >
        New catalog section
      </Button>
      <Box
        width={"100%"}
        height={"3px"}
        position={"absolute"}
        top={"50%"}
        left={0}
        right={0}
        zIndex={-1}
        background={"linear-gradient(to right, #66C8FF 33%, rgba(255,255,255,0) 0%)"}
        backgroundPosition={"bottom"}
        backgroundSize={"20px 2px"}
        backgroundRepeat={"repeat-x"}
      />
    </VStack>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HStack, VStack } from "@chakra-ui/react";

import { LoginModal } from "features/session/LoginModal";
import { DeepLinkModal } from "features/deepLink/deepLinkModal";
import { Background } from "components/background";
import { Header } from "components/header";
import { Footer } from "components/footer/Footer";

import { PurchaseFlow } from "../purchase";
import { purchaseThunks } from "features/purchase/purchaseSlice";
import { AddSectionButton } from "components/add-section-button";

import { WebshopNotFound } from "components/webshop-not-found";
import { ScrollContainer, ContentContainer } from "components/shop";
import { ErrorBoundary } from "components/error-boundary";

import { SectionComponents } from "components/sections";

import { validateItem } from "utils/storefront";
import { TRACKING_EVENTS, trackEvent } from "utils/tracking";

import {
  appSelector,
  storefrontSelector,
  globalThemeSelector,
  headerSelector,
  footerSelector,
  loginSelector,
  sectionsSelector,
  pricingTiersSelector,
  deepLinkSelector,
} from "features/app/appSlice";
import {
  sessionThunks,
  sessionSelector,
  setShowLogin,
} from "features/session/sessionSlice";
import {
  shopSelector,
  setCurrentViewedItem,
  setPurchaseFromDeepLink,
} from "./shopSlice";

import { getImageSrc, toKebabCase } from "utils/general";
import { extractLinks } from "utils/footer";
import isEmpty from "lodash.isempty";

import MessageHandler from "utils/message-handler";

export const Shop = () => {
  const dispatch = useDispatch();

  const appData = useSelector(appSelector);
  const {
    editorMode = false,
    previewMode = false,
    getWebshop = {},
    selectedSection = {},
  } = appData;
  const { requestStatus = {} } = getWebshop;

  const { currentViewedItem = null, purchaseFromDeepLink = false } =
    useSelector(shopSelector);

  const storefront = useSelector(storefrontSelector);
  const globalTheme = useSelector(globalThemeSelector);
  const header = useSelector(headerSelector);
  const footer = useSelector(footerSelector);
  const login = useSelector(loginSelector);
  const deepLink = useSelector(deepLinkSelector);
  const sections = useSelector(sectionsSelector);
  const pricingTiers = useSelector(pricingTiersSelector);

  const sessionData = useSelector(sessionSelector);
  const { loggedIn = false } = sessionData;

  const query = new URLSearchParams(window?.location.search);
  const section = query.get("section");
  const elementToScroll =
    section && document.getElementById(`section-${toKebabCase(section)}`);

  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);

  useEffect(() => {
    if (currentViewedItem && !requestStatus.pending) {
      const valid = validateItem(currentViewedItem, sections);

      if (loggedIn) {
        dispatch(setShowLogin(false));
      } else {
        dispatch(setShowLogin(true));
        return;
      }

      if (valid || purchaseFromDeepLink) {
        setShowPurchaseFlow(true);
        dispatch(
          purchaseThunks.createSession({
            item: currentViewedItem,
          }),
        );
      } else {
        trackEvent(TRACKING_EVENTS.ITEM_UNAVAILABLE, {
          item: currentViewedItem,
        });
        dispatch(setCurrentViewedItem(null));
        setShowPurchaseFlow(false);
      }
    } else {
      setShowPurchaseFlow(false);
    }
  }, [
    currentViewedItem,
    purchaseFromDeepLink,
    loggedIn,
    requestStatus,
    dispatch,
    sections,
  ]);

  const onLogin = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (editorMode) {
      MessageHandler.selectSection(login);
      return;
    }
    dispatch(setShowLogin(true));
  };

  const onLogout = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(sessionThunks.endSession());
  };

  const onItemSelect = (item) => {
    if (editorMode) return;
    trackEvent(TRACKING_EVENTS.ITEM_CLICK, { item });
    dispatch(setCurrentViewedItem(item));
  };

  const onAddSection = (order) => {
    MessageHandler.addNewSection({ order });
  };

  useEffect(() => {
    if (elementToScroll) {
      if (elementToScroll) {
        elementToScroll.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [elementToScroll]);

  return (
    <ErrorBoundary
      name="storefront"
      altUI={editorMode || previewMode ? null : <WebshopNotFound />}
      showAltUI={requestStatus.error}
      fallbackUI={<WebshopNotFound />}
    >
      <Background storefront={storefront} />

      <ErrorBoundary name={"deepLink"} show={!isEmpty(deepLink)}>
        <DeepLinkModal />
      </ErrorBoundary>

      <LoginModal />

      <Header
        id={header.id}
        logo={getImageSrc(header, "MAIN")}
        title={header.title}
        loggedIn={loggedIn}
        onLogin={onLogin}
        onLogout={onLogout}
        isSelected={selectedSection?.id === header?.id}
        onHeaderClick={() => MessageHandler.selectSection(header)}
      />

      <ScrollContainer>
        <ContentContainer>
          {editorMode && !sections.length && (
            <AddSectionButton
              pos="rel"
              onClick={() => {
                onAddSection(0);
              }}
            />
          )}

          {sections.map((section, index) => {
            return (
              <VStack
                key={`section-${section.id}`}
                width={"100%"}
                position={"relative"}
                alignItems={"center"}
                gap={"8px"}
                alignSelf={"stretch"}
                data-section-title={section.title}
                data-testid={`section-${section.id}`}
              >
                {editorMode && index === 0 && (
                  <AddSectionButton
                    pos="top"
                    onClick={() => {
                      onAddSection(section.order - 1);
                    }}
                  />
                )}

                <HStack
                  width={"100%"}
                  justifyContent={"center"}
                  onClick={() => {
                    MessageHandler.selectSection(section);
                  }}
                >
                  {(() => {
                    const SectionComponent = SectionComponents[section.type];
                    if (!SectionComponent) return null;
                    return (
                      <SectionComponent
                        loggedIn={loggedIn}
                        isLoading={
                          requestStatus.pending && !editorMode && !previewMode
                        }
                        selected={selectedSection.id === section.id}
                        section={section}
                        globalTheme={globalTheme}
                        pricingTiers={pricingTiers}
                        onItemSelect={editorMode ? () => {} : onItemSelect}
                      />
                    );
                  })()}
                </HStack>

                {editorMode && (
                  <AddSectionButton
                    pos="bottom"
                    onClick={() => {
                      onAddSection(section.order);
                    }}
                  />
                )}
              </VStack>
            );
          })}

          {showPurchaseFlow && (
            <PurchaseFlow
              isOpen={true}
              onClose={() => {
                dispatch(setPurchaseFromDeepLink(false));
                dispatch(setCurrentViewedItem(null));
              }}
            />
          )}
        </ContentContainer>
        <Footer
          id={footer.id}
          logo={getImageSrc(footer, "MAIN")}
          {...extractLinks(footer)}
          isSelected={selectedSection?.id === footer?.id}
          onFooterClick={() => MessageHandler.selectSection(footer)}
        />
      </ScrollContainer>
    </ErrorBoundary>
  );
};

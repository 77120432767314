export function calculateTimeLeft(endDate) {
  /*
    Formats:
      startTime: "2024-07-15T19:00:58.027"
      endTime: "2024-08-08T18:00:00"
  */

  if (!endDate) return null;

  // Parse endDate and get the current time in milliseconds
  const endDateTime = Date.parse(endDate.endsWith("Z") ? endDate : `${endDate}Z`);
  const nowTime = Date.now();
  const difference = endDateTime - nowTime;

  if (difference <= 0) return null;

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  if (days > 0) {
    // Return days and hours only if days are not zero
    return { d: days, h: hours };
  }
  if (hours > 0) {
    // Return hours and minutes only if hours are not zero and days are zero
    return { h: hours, m: minutes };
  }

  // Return minutes and seconds otherwise
  return { m: minutes, s: seconds };
}

export const checkTimeLeft = (timeLeft) => {
  if (!timeLeft) return false;
  if (
    typeof timeLeft === "object" &&
    Object.values(timeLeft).every((value) => value === 0)
  )
    return false;
  return true;
}

export const generateTimestamp = () => {
  const now = new Date();
  return now.toISOString();
}

export const modifyTimestamp = (timestamp, { days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  const date = new Date(timestamp);
  date.setDate(date.getDate() + days);
  date.setHours(date.getHours() + hours);
  date.setMinutes(date.getMinutes() + minutes);
  date.setSeconds(date.getSeconds() + seconds);
  return date.toISOString();
}

import { useEffect, useRef, useState } from "react";
export const useResizeObserver = (callback) => {
  const resizeObserver = useRef(null);
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }

    resizeObserver.current = new ResizeObserver((entries) => {
      callback(entries[0].contentRect);
    });

    if (node) {
      resizeObserver.current.observe(node);
    }

    return () => resizeObserver.current?.disconnect();
  }, [node, callback]);

  return [setNode];
};

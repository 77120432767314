import HTTP from "utils/http";

const SANLO_WEB_API_URL = import.meta.env.VITE_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/webshops`;

class SessionService {
  createSession(userData) {
    const { webshopId = "", ...data } = userData;
    return HTTP.req.post(`${BASE}/${webshopId}/player/session`, data);
  }

  createSocialSession({ webshopId = "", authMethod = "" }) {
    return HTTP.req.post(`${BASE}/${webshopId}/player/session/social`, {
      authMethod,
    });
  }
}

export default new SessionService();

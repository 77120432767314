import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";

import { Login } from "components/login/Login";

import { appSelector, layoutSelector } from "features/app/appSlice";
import {
  sessionSelector,
  setShowLogin,
  resetRequestStatus,
} from "features/session/sessionSlice";
import {
  setCurrentViewedItem,
  setPurchaseFromDeepLink,
} from "features/shop/shopSlice";

import MessageHandler, { MESSAGE_TYPES } from "utils/message-handler";

export const LoginModal = () => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const appData = useSelector(appSelector);
  const sessionData = useSelector(sessionSelector);
  const { login = {} } = useSelector(layoutSelector);
  const { editorMode = false } = appData;
  const { showLogin = false } = sessionData;

  useEffect(() => {
    MessageHandler.setupListener("login", {
      [MESSAGE_TYPES.SECTION_SELECTED]: (data) => {
        const { type = "" } = data;
        if (type === "LOGIN") {
          dispatch(setShowLogin(true));
        } else {
          dispatch(setShowLogin(false));
        }
      },
    });
    return () => {
      MessageHandler.removeListener("login");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showLogin) {
      onOpen();
    } else {
      onClose();
    }
  }, [onClose, onOpen, showLogin]);

  return (
    <Login
      isOpen={isOpen}
      onClose={() => {
        dispatch(setShowLogin(false));
        dispatch(setCurrentViewedItem(null));
        dispatch(setPurchaseFromDeepLink(false));
        dispatch(resetRequestStatus());
      }}
      editorMode={editorMode}
      login={login}
    />
  );
};

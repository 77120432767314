export const AUTH_METHODS = {
  MANUAL: "MANUAL",
  PASSWORD: "PASSWORD",
};

export const AUTH_CATEGORY = {
  USER_ID: "USER_ID",
  PASSWORD: "PASSWORD",
  OAUTH: "OAUTH",
};

export const OAUTH_FLOW_DATA = "oauth_flow_data";

import React from "react";
import ReactDOM from "react-dom/client";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";

import { Provider } from "react-redux";
import { store } from "./store";

import { App } from "./features/app";

import "./index.css";

const data = window.__PRELOADED_DATA__ || {};
const ENV = data.env || import.meta.env.VITE_APP_ENV;
const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
const LD_CLIENT_ID = import.meta.env.VITE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

if (ENV !== "local") {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app\.sanlo\.io/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: ENV,
    // release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    denyUrls: [
      // LD events on beforeunload
      /events\.launchdarkly\.com\/events\/bulk/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID,
    user: {
      key: data?.webshopId,
    },
  });

  const domNode = document.getElementById("root");
  const root = ReactDOM.createRoot(domNode);

  /*
    Note: Strict mode currently causes some problems with Adyen Drop In and
    Components for PayPal at least. Disabled for now.
  */

  root.render(
    <LDProvider>
      {/* <React.StrictMode> */}
        <Provider store={store}>
          <App preloadedData={data} />
        </Provider>
      {/* </React.StrictMode> */}
    </LDProvider>,
  );
})();

import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

const variants = {
  enters: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
  }),
  center: {
    x: 0,
  },
  exit: (direction) => ({
    x: direction < 1 ? -1000 : 1000,
  }),
};

export const PurchaseFlowModal = ({
  isOpen = false,
  onClose = () => {},
  step = 0,
  direction = 0,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"md"}
      onCloseComplete={() => {
        // setStep([0, 0]);
      }}
      data-testid={"purchase-flow-modal"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        maxH={"80vh"}
        overflow={"auto"}
        bg={"transparent"}
        boxShadow={"none"}
        data-testid={"purchase-flow-modal-content"}
        pt={"30px"}
      >
        <ModalCloseButton />
        <Box
          opacity={1}
          bg={"modalBackground"}
          color={"modalBackgroundContrast"}
          borderRadius={"12px"}
          boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px"}
        >
          <AnimatePresence initial={false} mode="popLayout">
            <motion.div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
              variants={variants}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              initial={"enters"}
              animate={"center"}
              custom={direction}
              exit={"exit"}
              key={step}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </Box>
      </ModalContent>
    </Modal>
  );
};

import axios from "axios";
import Cookies from 'js-cookie';

const createJWTToken = (token) => {
  return "Bearer " + token;
};

class HTTP {
  constructor() {
    this.token = null;
    this.req = axios.create({
      baseURL: "/",
      headers: {
        "Content-type": "application/json",
      },
    });
    this.interceptorSet = false;
    this.userCookieKey = null;
  }

  checkUserCookie (webshopId) {
    this.userCookieKey = `user-${webshopId}`;
    const user = this.getUserCookie();
    if (user) this.setAuthorization(user);
    return user;
  }

  getUserCookie () {
    const user = Cookies.get(this.userCookieKey);
    if (!user) return null;
    const parsedData = JSON.parse(user);
    return parsedData;
  }

  setUserCookie (user) {
    const cookieData = JSON.stringify(user);
    Cookies.set(this.userCookieKey, cookieData, {
      secure: true,
      sameSite: 'Strict',
      expires: 1,
    });
  }

  deleteUserCookie () {
    Cookies.remove(this.userCookieKey);
  }

  setAuthorization (user) {
    const { accessToken: token = "" } = user;
    this.token = createJWTToken(token);
    this.setUserCookie(user);
    if (this.interceptorSet) return;
    this.interceptorSet = true;
    this.req.interceptors.request.use((config) => {
      config.headers["Authorization"] = this.token;
      return config;
    });
  }

  removeAuthorization () {
    this.token = null;
    this.deleteUserCookie();
  }
}

export default new HTTP();

import { cloneElement, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { PurchaseFlowModal } from "components/purchase/PurchaseFlowModal";
import { PurchaseReviewContainer } from "components/purchase/PurchaseReview";
import { PurchaseCheckout } from "components/purchase/PurchaseCheckout";
import { PurchaseResult } from "components/purchase/PurchaseResult";

import {
  purchaseThunks,
  purchaseResultSelector,
  purchaseModalSelector,
  purchaseSessionSelector,
  setModalStep,
  setModalClose,
  setPurchaseSuccess,
  setPurchaseError,
} from "features/purchase/purchaseSlice";
import { shopSelector } from "features/shop/shopSlice";

import {
  validatePurchaseResult,
  validatePurchaseError,
  CREATE_SESSION_FAILED,
} from "utils/purchase";

import { TRACKING_EVENTS, trackEvent } from "utils/tracking";
import { appSelector, appThunks } from "features/app/appSlice";

const steps = [
  <PurchaseReviewContainer key={0} />,
  <PurchaseCheckout key={1} />,
  <PurchaseResult key={2} />,
];
// A Checkout step is any step up to the actual result (success or error)
const isCheckoutStep = (step) => step === 0 || step === 1;

export const PurchaseFlow = ({ isOpen = false, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { webshopId } = useSelector(appSelector);

  const { currentViewedItem = null } = useSelector(shopSelector);
  const purchaseResult = useSelector(purchaseResultSelector);
  const modal = useSelector(purchaseModalSelector);
  const { step, direction } = modal;

  const session = useSelector(purchaseSessionSelector);

  const onPurchaseSuccess = (data) => {
    dispatch(setPurchaseSuccess(data));
  };

  const onPurchaseError = (data) => {
    dispatch(setPurchaseError(data));
  };

  const handleClose = () => {
    onClose();
    dispatch(setModalClose());
    if (!isCheckoutStep(step)) {
      // Only refetch the webshop for purchase success or error
      // not if the user closes out of the modal during a checkout step
      dispatch(appThunks.getWebshop(webshopId));
    }
  };

  const nextStep = () => {
    dispatch(
      setModalStep({
        next: true,
        stepsLength: steps.length,
      })
    );
  };

  const prevStep = (opts = {}) => {
    const { reset = false } = opts;
    dispatch(
      setModalStep({
        ...opts,
        prev: true,
        stepsLength: steps.length,
      })
    );
    if (reset && currentViewedItem) {
      dispatch(
        purchaseThunks.createAndConfirmSession({
          item: currentViewedItem,
        })
      );
    }
  };

  useEffect(() => {
    if (!session.isConfirmed) return;

    if (session.free) {
      dispatch(
        setPurchaseSuccess({
          price: "free",
          status: "success",
        })
      );
      dispatch(
        setModalStep({
          step: 2,
          stepsLength: steps.length,
        })
      );
    } else {
      dispatch(
        setModalStep({
          next: true,
          stepsLength: steps.length,
        })
      );
    }
  }, [session.free, session.isConfirmed]);

  useEffect(() => {
    if (!isOpen) dispatch(setModalClose());
  }, [isOpen]);

  useEffect(() => {
    const { success, error } = purchaseResult;
    const goToPurchaseResult =
      validatePurchaseResult(success) ||
      validatePurchaseError(error) ||
      error?.error === CREATE_SESSION_FAILED;

    if (goToPurchaseResult) {
      dispatch(
        setModalStep({
          step: 2,
          direction: 1,
          stepsLength: steps.length,
        })
      );
    }
  }, [purchaseResult]);

  return (
    <PurchaseFlowModal
      isOpen={isOpen}
      onClose={() => {
        if (isCheckoutStep(step)) {
          trackEvent(TRACKING_EVENTS.ITEM_DISMISSED, {
            item: currentViewedItem,
          });
        }
        handleClose();
      }}
      step={step}
      direction={direction}
    >
      {cloneElement(steps[step], {
        item: currentViewedItem,
        session,
        purchaseResult,
        handleClose,
        onPurchaseSuccess,
        onPurchaseError,
        handleNextStep: nextStep,
        handlePrevStep: prevStep,
      })}
    </PurchaseFlowModal>
  );
};

PurchaseFlow.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

import PropTypes from "prop-types";
import { Flex, Spinner } from "@chakra-ui/react";

export const ItemLoader = ({
  isLoading = false,
}) => {
  if (!isLoading) return null;

  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.5)"
      zIndex={4}
      justifyContent="center"
      alignItems="center"
    >
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="var(--section-primary)"
        size="xl"
      />
    </Flex>
  );
};

ItemLoader.propTypes = {
  isLoading: PropTypes.bool,
};

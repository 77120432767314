import { Heading } from "@chakra-ui/react";
import PropTypes from "prop-types";

import { getSectionHeaderStyles } from "utils/customData";

export const SectionHeader = ({ section = {}, ...rest }) => {
  const { title = "", themeOverride = {} } = section;

  if (!title) {
    return null;
  }

  return (
    <Heading
      size={"headline"}
      fontFamily={"sectionHeader"}
      width={"100%"}
      textAlign={"center"}
      pt="8px"
      mb={"24px"}
      lineHeight={{
        lg: "40px",
        md: "36px",
        base: "32px",
      }}
      letterSpacing={"-1px"}
      {...getSectionHeaderStyles(
        themeOverride,
        section,
        "headlineHeadingColor",
      )}
      {...rest}
    >
      {title}
    </Heading>
  );
};

SectionHeader.propTypes = {
  section: PropTypes.object,
};

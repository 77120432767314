import Bowser from "bowser";

// This script is used to get the user's browser, OS, screen size, and other details.
// It is used to track the user's device and browser information.
export const getDeviceInfo = () => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  const browser = parser.getBrowser();
  const os = parser.getOS();
  const platform = parser.getPlatform();

  return {
    browser: browser.name,
    browserVersion: browser.version,
    browserMajorVersion: browser.major,
    isMobile: platform.type === "mobile",
    os: os.name,
    osVersion: os.version,
  };
};

import { Button, Icon, Image, Heading, HStack } from "@chakra-ui/react";

import UserIcon from "images/icons/user.svg?react";
import LogOutIcon from "images/icons/log-out.svg?react";

export const Header = ({
  id = "",
  logo = "",
  title = "",
  loggedIn = false,
  onLogin = () => {},
  onLogout = () => {},
  isSelected = false,
  onHeaderClick = () => {},
}) => {
  return (
    <HStack
      bg={"headerBackground"}
      height={"var(--header-height)"}
      textAlign={"left"}
      color={"headerTitle"}
      boxShadow={"md"}
      flexShrink={0}
      backdropFilter={"blur(5px)"}
      position={"absolute"}
      width={"100%"}
      zIndex={10}
      justify={"center"}
      data-scroll-target={`section-${id}`}
      outline={`2px solid ${
        isSelected ? "var(--chakra-colors-brandRed)" : "transparent"
      }`}
      outlineOffset={"-8px"}
      onClick={onHeaderClick}
    >
      <HStack
        justify={"space-between"}
        align={"center"}
        width={"100%"}
        maxW={"var(--max-width)"}
        p={"0 16px"}
      >
        <HStack gap={"12px"} alignContent={"center"} justifyContent={"center"}>
          {logo && <Image src={logo} height={"60px"} borderRadius={"8px"} />}
          <Heading size={"large"} fontWeight={"bold"} color={"headerTitle"}>
            {title}
          </Heading>
        </HStack>

        <HStack
          padding="2px"
          bg="headerBackground"
          borderRadius="48px"
          boxShadow="md"
        >
          {/* TODO: Language dropdown eventually */}

          <Button
            bg={"headerPrimary"}
            padding={"12px 16px"}
            borderRadius={"48px"}
            position={"relative"}
            cursor={"pointer"}
            color={"headerPrimaryContrast"}
            boxShadow={"md"}
            onClick={(e) => (loggedIn ? onLogout(e) : onLogin(e))}
            _hover={{
              textDecoration: "underline",
            }}
            _active={{
              textDecoration: "underline",
            }}
          >
            {loggedIn && "Logout"}
            <Icon
              as={loggedIn ? LogOutIcon : UserIcon}
              fill={"white"}
              height={"24px"}
              width={"24px"}
              ml={loggedIn ? "10px" : "0"}
            />
          </Button>
        </HStack>
      </HStack>
    </HStack>
  );
};

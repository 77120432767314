import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";

import { Offer } from "components/offer/Offer";

import {
  appSelector,
  globalThemeSelector,
  pricingTiersSelector,
  deepLinkSelector,
} from "features/app/appSlice";
import {
  offerSelector,
  setShowOffer,
  resetOfferRequestStatus,
} from "features/deepLink/deepLinkSlice";
import {
  setCurrentViewedItem,
  setPurchaseFromDeepLink,
} from "features/shop/shopSlice";

import MessageHandler, { MESSAGE_TYPES } from "utils/message-handler";
import { TRACKING_EVENTS, trackEvent } from "utils/tracking";

export const DeepLinkModal = () => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const pricingTiers = useSelector(pricingTiersSelector);
  const appData = useSelector(appSelector);
  const { editorMode = false } = appData;

  const globalTheme = useSelector(globalThemeSelector);
  const deepLink = useSelector(deepLinkSelector);
  const offerData = useSelector(offerSelector);
  const { showOffer = false, data = {} } = offerData;

  const handlePurchaseOffer = () => {
    if (editorMode) return;
    trackEvent(TRACKING_EVENTS.OFFER_CLICK, { item: data });
    dispatch(setPurchaseFromDeepLink(true));
    dispatch(setCurrentViewedItem(data));
    onClose();
  };

  useEffect(() => {
    MessageHandler.setupListener("deepLink", {
      [MESSAGE_TYPES.SECTION_SELECTED]: (data) => {
        const { type = "" } = data;
        if (type === "DEEPLINK") {
          dispatch(setShowOffer(true));
        } else {
          dispatch(setShowOffer(false));
        }
      },
    });
    return () => {
      MessageHandler.removeListener("deepLink");
    };
  }, [dispatch]);

  useEffect(() => {
    if (showOffer) {
      onOpen();
    } else {
      onClose();
    }
  }, [onClose, onOpen, showOffer]);

  return (
    <Offer
      editorMode={editorMode}
      isOpen={isOpen}
      layout={deepLink}
      item={data}
      pricingTiers={pricingTiers}
      globalTheme={globalTheme}
      handlePurchaseOffer={handlePurchaseOffer}
      onClose={() => {
        dispatch(setShowOffer(false));
        dispatch(resetOfferRequestStatus());
      }}
    />
  );
};

import HTTP from "utils/http";

const SANLO_WEB_API_URL = import.meta.env.VITE_APP_SANLO_WEB_API_URL;
const BASE = `${SANLO_WEB_API_URL}/api/fairground/webshops`;

class PurchaseService {
  createSession(data) {
    return HTTP.req.post(`${BASE}/purchase/session/v2`, { ...data });
  }

  updateSession({ sessionId, data }) {
    return HTTP.req.put(`${BASE}/purchase/session/v2/${sessionId}`, {
      ...data,
    });
  }

  confirmSession(sessionId) {
    return HTTP.req.post(`${BASE}/purchase/session/v2/${sessionId}/confirm`);
  }
}

export default new PurchaseService();

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  getBackgroundStyles,
  getContrastingStyles,
  themeToCSSVariables,
  getContrastColor,
} from "utils/theme";
import { getPrice } from "utils/general";

import { OfferError } from "./OfferError";
import { OfferSuccess } from "./OfferSuccess";

import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";

export const Offer = ({
  editorMode = false,
  isOpen = false,
  item = {},
  layout = {},
  pricingTiers = {},
  globalTheme = {},
  handlePurchaseOffer = () => {},
  onClose = () => {},
}) => {
  const { pricingTierLevelId = "" } = item;
  const {
    bannerImageBackgroundColor = "",
    themeOverride = {},
    files = [],
  } = layout;

  const itemPrice = getPrice(pricingTiers[pricingTierLevelId]);
  const showError = isEmpty(item) || !item?.available;

  let buttonText;
  if (showError && !editorMode) {
    buttonText = "View shop";
  } else {
    buttonText = editorMode ? "$ X.XX" : itemPrice;
  }

  const handleButtonClick = () => {
    if (editorMode) return;
    if (showError) {
      onClose();
    } else {
      handlePurchaseOffer();
    }
  };

  return (
    <Modal
      onClose={() => {
        if (editorMode) return;
        onClose();
      }}
      size={"md"}
      isOpen={isOpen}
      autoFocus={!editorMode}
      trapFocus={!editorMode}
      closeOnOverlayClick={!editorMode}
      isCentered
      m={"auto"}
    >
      <ModalOverlay bg={"none"} backdropFilter={"auto"} backdropBlur={"4px"} />
      <ModalContent
        p={0}
        alignItems={"center"}
        position={{
          md: "relative",
          base: "absolute",
        }}
        bottom={{
          md: "auto",
          base: "0",
        }}
        width="100%"
        maxWidth={{
          md: "430px",
          base: "100%",
        }}
        borderRadius={{
          md: "24px",
          base: "0",
        }}
      >
        <Box
          w={"100%"}
          h={isEmpty(files) ? "160px" : "200px"}
          borderTopLeftRadius={"24px"}
          borderTopRightRadius={"24px"}
          {...getBackgroundStyles(themeOverride, files)}
          bgColor={bannerImageBackgroundColor}
        />

        <ModalCloseButton
          color={getContrastColor(bannerImageBackgroundColor)}
          size={"lg"}
        />

        <Box
          w={"100%"}
          p={"24px 32px 32px 32px"}
          borderBottomLeftRadius={"24px"}
          borderBottomRightRadius={"24px"}
          {...getBackgroundStyles(themeOverride, [])}
          sx={{ ...themeToCSSVariables(themeOverride, globalTheme) }}
        >
          {showError && !editorMode ? (
            <OfferError themeOverride={layout?.themeOverride} />
          ) : (
            <OfferSuccess editorMode={editorMode} item={item} layout={layout} />
          )}

          <ModalFooter p={"0"} mt={"18px"}>
            <Flex w={"100%"} justifyContent={"center"} alignItems={"center"}>
              <Button
                w={"200px"}
                py={"20px"}
                borderRadius={"12px"}
                fontSize={"20px"}
                fontWeight={800}
                lineHeight={"30px"}
                boxShadow={"-1px 3px 8px 2px rgba(0, 0, 0, 0.25);"}
                _hover={{
                  filter: "brightness(1.2)",
                }}
                _active={{
                  filter: "brightness(0.8)",
                }}
                {...getContrastingStyles(
                  themeOverride?.primary?.color,
                  "sectionPrimary",
                  {
                    addShadow: true,
                  },
                )}
                onClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            </Flex>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
};

Offer.propTypes = {
  editorMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  item: PropTypes.object,
  layout: PropTypes.object,
  pricingTiers: PropTypes.object,
  globalTheme: PropTypes.object,
  handlePurchaseOffer: PropTypes.func,
  onClose: PropTypes.func,
};

import numeral from "numeral";

import { getFreeLabel } from "./customData";

export const copy = (objToCopy) => {
  // Object.assign only does a shallow copy
  try {
    return JSON.parse(JSON.stringify(objToCopy));
  } catch (e) {
    // We don't want to return null because its type is object
    // and type checking will fail if used
    return undefined;
  }
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const scrollToTarget = (target) => {
  try {
    document.querySelector(`[data-scroll-target="${target}"]`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  } catch (e) {
    // Node wasn't found or something, should be fine
  }
};

export const getImageSrc = (files = [], fileName = "MAIN") => {
  try {
    let images = files;
    if (!Array.isArray(files) && typeof files === "object") {
      if (files.files) {
        images = files.files;
      }
    }
    const image = images.find((file) => file.name === fileName);
    const url = image.url || "";
    // Base64 images come from the editor at the moment
    const base64 = image.base64 || "";
    return base64 || url;
  } catch (e) {
    return "";
  }
};

export const getPrice = (pricingTier, meta = {}) => {
  if (!pricingTier?.price) return "";

  // Can be used to alter price formats based on user state
  // const { loggedIn = false } = meta;

  const {
    amountCents = 0,
    symbol = "",
    displayValue = "", // formatted price without symbol
  } = pricingTier.price;

  if (amountCents === 0) return getFreeLabel("Free");

  let priceInDollars = null;
  if (displayValue) {
    priceInDollars = displayValue;
  } else {
    priceInDollars = (amountCents / 100).toFixed(2);
  }

  return `${symbol} ${priceInDollars}`;
};

/**
 * Parses a number to the default thousands format (shorthand)
 * 1000 -> 1K
 * 1050 -> 1.05K
 * 1005 -> 1K (rounded)
 * 10000 -> 10K
 * 1000000 -> 1M
 * @param input The number to format
 * @returns {string} The default SANLO number formatting for large numbers
 */
export const parseThousands = (input) =>
  numeral(input).format("(0,0[.]00a)").toUpperCase();


export function toKebabCase(str) {
  if (!str) return "";
  return str
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/_/g, "-") // Replace underscores with hyphens
    .replace(/[^a-z0-9-]/g, "") // Remove non-alphanumeric characters (excluding hyphens)
    .replace(/(-)+/g, "-") // Replace multiple hyphens with a single hyphen
    .replace(/(^-+)|(-+$)/g, ""); // Remove leading and trailing hyphens
}

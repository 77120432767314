export const hexToRgb = (hexValue, a = 1, opts = {}) => {
  const {
    colorMultiplier = 1,
  } = opts;

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hexValue = hexValue.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue);
  if (!result) return null;

  const r = parseInt(result[1], 16) * colorMultiplier;
  const g = parseInt(result[2], 16) * colorMultiplier;
  const b = parseInt(result[3], 16) * colorMultiplier;

  return {
    r,
    g,
    b,
    a,
    rgba: `rgba(${r}, ${g}, ${b}, ${a})`,
  };
};

import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";

import {
  Heading,
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ManualAuth } from "./ManualAuth";
import { SocialAuth } from "./SocialAuth";
import { AUTH_CATEGORY } from "src/consts/authMethods";
import { PasswordAuth } from "./PasswordAuth";
import { Skeleton } from "components/skeleton/Skeleton";

import { getAuthEmailError } from "utils/customData";
import { copy } from "utils/general";

export const Login = ({
  isOpen = false,
  onClose = () => {},
  editorMode = false,
  login = {},
}) => {
  const providers = login?.providers ?? [];

  const manualProvider = providers.find(
    (provider) => provider.authCategory === AUTH_CATEGORY.USER_ID,
  );
  const passwordProvider = copy(
    providers.find(
      (provider) => provider.authCategory === AUTH_CATEGORY.PASSWORD,
    ),
  );
  if (passwordProvider) {
    passwordProvider.errorMessage = getAuthEmailError();
  }
  const socialProviders = providers.filter(
    (provider) =>
      provider.authCategory === AUTH_CATEGORY.OAUTH && provider.visible,
  );
  const manualAuthProviders =
    passwordProvider?.visible && manualProvider?.visible;

  return (
    <Modal
      onClose={() => {
        if (editorMode) return;
        onClose();
      }}
      size="md"
      isOpen={isOpen}
      isCentered
      autoFocus={!editorMode}
      trapFocus={!editorMode}
      closeOnOverlayClick={!editorMode}
      motionPreset="slideInBottom"
    >
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="4px" />
      <ModalContent
        p="32px"
        bg="loginBackground"
        position={{
          md: "relative",
          base: "absolute",
        }}
        bottom={{
          md: "auto",
          base: "0",
        }}
        width="100%"
        maxWidth={{
          md: "430px",
          base: "100%",
        }}
        borderRadius={{
          md: "24px",
          base: "0",
        }}
      >
        <ModalCloseButton size={"lg"} />
        {Boolean(login.modalTitle) && (
          <Heading
            size={"large"}
            p={"0"}
            mb={"32px"}
            color={"loginTitle"}
            textAlign={"center"}
          >
            {login.modalTitle}
          </Heading>
        )}
        <ModalBody p="0">
          {isEmpty(providers) && <Skeleton />}
          {passwordProvider && <PasswordAuth provider={passwordProvider} />}
          {manualAuthProviders && (
            <Text variant="loginGeneric" mb="16px">
              Or, log in with your player ID
            </Text>
          )}
          {manualProvider && (
            <ManualAuth editorMode={editorMode} provider={manualProvider} />
          )}
        </ModalBody>
        {!isEmpty(socialProviders) && (
          <SocialAuth
            editorMode={editorMode}
            onlySocial={!manualAuthProviders && !manualProvider}
            socialProviders={socialProviders}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

Login.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editorMode: PropTypes.bool,
  login: PropTypes.object,
};
